import { Avatar, Breadcrumb, Button, Divider, List, message, Popconfirm, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { PlusOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { browserHistory } from 'src/router';
import { apiPatient } from 'src/services/doctors/mods/patient';
import './Family.scss';

const Family: React.FC = () => {
  const [dataSource, setdataSource] = useState<Array<defs.doctors.PatientFamilyMemberModel>>([]);
  const [page, setpage] = useState(1);

  const [getHistoricalAppointmentTrigger, { data, isFetching: loading }] = apiPatient.useLazyGetFamilyMemberQuery();
  const [deleteFamilyMemberByIdTrigger] = apiPatient.useDeleteFamilyMemberByIdMutation();

  const loadMoreData = () => {
    if (loading) {
      return;
    }
    getHistoricalAppointmentTrigger({ queryParams: { page, size: 20 } })
      .unwrap()
      .then(res => {
        setpage(page + 1);
        setdataSource([...dataSource, ...res.results]);
      });
  };

  useEffect(() => {
    loadMoreData();
  }, []);
  const handleEdit = (id: number) => {
    browserHistory.push(`/account/family/edit/${id}`);
  };
  const handleDel = (id: number) => {
    deleteFamilyMemberByIdTrigger({ id })
      .unwrap()
      .then(() => {
        message.success('Successfully deleted');
        const newData = _.filter(dataSource, item => item.id != id);
        setdataSource(newData);
      });
  };
  return (
    <div className="page-family">
      <div className="family-header">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/account">Account</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Family Menber</Breadcrumb.Item>
        </Breadcrumb>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => browserHistory.push('/account/family/edit')}
        ></Button>
      </div>
      <div id="scrollfamily" className="family-content">
        <InfiniteScroll
          dataLength={dataSource.length}
          next={loadMoreData}
          hasMore={dataSource.length < data?.count || dataSource.length === 0}
          loader={''}
          endMessage={dataSource.length > 0 && <Divider plain>It is all, nothing more 🤐</Divider>}
          scrollableTarget="scrollfamily"
        >
          <List
            dataSource={dataSource}
            renderItem={(item, index) => (
              <div className="family-item">
                <div className="icon">
                  <Avatar size="large" icon={index + 1} />
                </div>
                <div className="content">
                  <div>{item.fullName}</div>
                  <div>{item.dob}</div>
                </div>
                <div className="action">
                  <span
                    className="iconfont doc-bianji"
                    style={{ color: '#1677ff', marginRight: 20 }}
                    onClick={() => handleEdit(item.id)}
                  ></span>
                  <Popconfirm
                    title="Are you sure to delete?"
                    onConfirm={() => handleDel(item.id)}
                    okText="Yes"
                    cancelText="No"
                    placement="left"
                  >
                    <span className="iconfont doc-shanchu" style={{ color: 'red' }}></span>
                  </Popconfirm>
                </div>
              </div>
            )}
          />
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default Family;
