import { Button, Result } from 'antd';
import React from 'react';
import { browserHistory } from 'src/router';

const NotFound: React.FC = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button
            type="primary"
            onClick={() => {
              browserHistory.replace('/book');
            }}
          >
            Back Home
          </Button>
        }
      />
    </div>
  );
};

export default NotFound;
