/* eslint-disable react-hooks/rules-of-hooks */
// @ts-nocheck
import { pontApis } from '../../../pontApis';
import * as postAuthForgetPassword from './postAuthForgetPassword';
import * as postAuthGuest from './postAuthGuest';
import * as postAuthLogin from './postAuthLogin';
import * as postAuthLogoutAll from './postAuthLogoutAll';
import * as postAuthLogout from './postAuthLogout';
import * as postAuthMessagingToken from './postAuthMessagingToken';
import * as putAuthMessagingTokenById from './putAuthMessagingTokenById';
import * as deleteAuthMessagingTokenById from './deleteAuthMessagingTokenById';
import * as postAuthRegister from './postAuthRegister';
import * as postAuthRegisterValidateEmail from './postAuthRegisterValidateEmail';
import * as getAuthUserProfile from './getAuthUserProfile';
import * as putAuthUserProfile from './putAuthUserProfile';
import * as postContactUs from './postContactUs';
import * as getFamilyMember from './getFamilyMember';
import * as postFamilyMember from './postFamilyMember';
import * as getFamilyMemberById from './getFamilyMemberById';
import * as putFamilyMemberById from './putFamilyMemberById';
import * as patchFamilyMemberById from './patchFamilyMemberById';
import * as deleteFamilyMemberById from './deleteFamilyMemberById';
import * as getHistoricalAppointment from './getHistoricalAppointment';
import * as getMyCareTeamPractice from './getMyCareTeamPractice';
import * as postMyCareTeamPracticeAddByPracticePk from './postMyCareTeamPracticeAddByPracticePk';
import * as postMyCareTeamPracticeRemoveByPracticePk from './postMyCareTeamPracticeRemoveByPracticePk';
import * as getMyCareTeamPractitioner from './getMyCareTeamPractitioner';
import * as postMyCareTeamPractitionerAddByPractitionerPk from './postMyCareTeamPractitionerAddByPractitionerPk';
import * as postMyCareTeamPractitionerRemoveByPractitionerPk from './postMyCareTeamPractitionerRemoveByPractitionerPk';
import * as getPayment from './getPayment';
import * as postPayment from './postPayment';
import * as deletePaymentById from './deletePaymentById';
import * as getPractice from './getPractice';
import * as getPracticeById from './getPracticeById';
import * as getPracticeByIdAppointmentType from './getPracticeByIdAppointmentType';
import * as getPractitioner from './getPractitioner';
import * as getPractitionerById from './getPractitionerById';
import * as getPractitionerByIdAppointmentType from './getPractitionerByIdAppointmentType';
import * as getPractitionerByIdFreeAppointments from './getPractitionerByIdFreeAppointments';
import * as getSearch from './getSearch';
import * as getSearchProtected from './getSearchProtected';
import * as getUpcomingAppointment from './getUpcomingAppointment';
import * as getUpcomingAppointmentById from './getUpcomingAppointmentById';
import * as postUpcomingAppointmentByIdCancelConfirm from './postUpcomingAppointmentByIdCancelConfirm';
import * as postUpcomingAppointmentByIdCancel from './postUpcomingAppointmentByIdCancel';
import * as postUpcomingAppointmentByIdCheckIn from './postUpcomingAppointmentByIdCheckIn';

const { responseInitialValue: postAuthForgetPasswordInit, endpoint: postAuthForgetPasswordEndpoint } =
  postAuthForgetPassword;
const { responseInitialValue: postAuthGuestInit, endpoint: postAuthGuestEndpoint } = postAuthGuest;
const { responseInitialValue: postAuthLoginInit, endpoint: postAuthLoginEndpoint } = postAuthLogin;
const { responseInitialValue: postAuthLogoutAllInit, endpoint: postAuthLogoutAllEndpoint } = postAuthLogoutAll;
const { responseInitialValue: postAuthLogoutInit, endpoint: postAuthLogoutEndpoint } = postAuthLogout;
const { responseInitialValue: postAuthMessagingTokenInit, endpoint: postAuthMessagingTokenEndpoint } =
  postAuthMessagingToken;
const { responseInitialValue: putAuthMessagingTokenByIdInit, endpoint: putAuthMessagingTokenByIdEndpoint } =
  putAuthMessagingTokenById;
const { responseInitialValue: deleteAuthMessagingTokenByIdInit, endpoint: deleteAuthMessagingTokenByIdEndpoint } =
  deleteAuthMessagingTokenById;
const { responseInitialValue: postAuthRegisterInit, endpoint: postAuthRegisterEndpoint } = postAuthRegister;
const { responseInitialValue: postAuthRegisterValidateEmailInit, endpoint: postAuthRegisterValidateEmailEndpoint } =
  postAuthRegisterValidateEmail;
const { responseInitialValue: getAuthUserProfileInit, endpoint: getAuthUserProfileEndpoint } = getAuthUserProfile;
const { responseInitialValue: putAuthUserProfileInit, endpoint: putAuthUserProfileEndpoint } = putAuthUserProfile;
const { responseInitialValue: postContactUsInit, endpoint: postContactUsEndpoint } = postContactUs;
const { responseInitialValue: getFamilyMemberInit, endpoint: getFamilyMemberEndpoint } = getFamilyMember;
const { responseInitialValue: postFamilyMemberInit, endpoint: postFamilyMemberEndpoint } = postFamilyMember;
const { responseInitialValue: getFamilyMemberByIdInit, endpoint: getFamilyMemberByIdEndpoint } = getFamilyMemberById;
const { responseInitialValue: putFamilyMemberByIdInit, endpoint: putFamilyMemberByIdEndpoint } = putFamilyMemberById;
const { responseInitialValue: patchFamilyMemberByIdInit, endpoint: patchFamilyMemberByIdEndpoint } =
  patchFamilyMemberById;
const { responseInitialValue: deleteFamilyMemberByIdInit, endpoint: deleteFamilyMemberByIdEndpoint } =
  deleteFamilyMemberById;
const { responseInitialValue: getHistoricalAppointmentInit, endpoint: getHistoricalAppointmentEndpoint } =
  getHistoricalAppointment;
const { responseInitialValue: getMyCareTeamPracticeInit, endpoint: getMyCareTeamPracticeEndpoint } =
  getMyCareTeamPractice;
const {
  responseInitialValue: postMyCareTeamPracticeAddByPracticePkInit,
  endpoint: postMyCareTeamPracticeAddByPracticePkEndpoint
} = postMyCareTeamPracticeAddByPracticePk;
const {
  responseInitialValue: postMyCareTeamPracticeRemoveByPracticePkInit,
  endpoint: postMyCareTeamPracticeRemoveByPracticePkEndpoint
} = postMyCareTeamPracticeRemoveByPracticePk;
const { responseInitialValue: getMyCareTeamPractitionerInit, endpoint: getMyCareTeamPractitionerEndpoint } =
  getMyCareTeamPractitioner;
const {
  responseInitialValue: postMyCareTeamPractitionerAddByPractitionerPkInit,
  endpoint: postMyCareTeamPractitionerAddByPractitionerPkEndpoint
} = postMyCareTeamPractitionerAddByPractitionerPk;
const {
  responseInitialValue: postMyCareTeamPractitionerRemoveByPractitionerPkInit,
  endpoint: postMyCareTeamPractitionerRemoveByPractitionerPkEndpoint
} = postMyCareTeamPractitionerRemoveByPractitionerPk;
const { responseInitialValue: getPaymentInit, endpoint: getPaymentEndpoint } = getPayment;
const { responseInitialValue: postPaymentInit, endpoint: postPaymentEndpoint } = postPayment;
const { responseInitialValue: deletePaymentByIdInit, endpoint: deletePaymentByIdEndpoint } = deletePaymentById;
const { responseInitialValue: getPracticeInit, endpoint: getPracticeEndpoint } = getPractice;
const { responseInitialValue: getPracticeByIdInit, endpoint: getPracticeByIdEndpoint } = getPracticeById;
const { responseInitialValue: getPracticeByIdAppointmentTypeInit, endpoint: getPracticeByIdAppointmentTypeEndpoint } =
  getPracticeByIdAppointmentType;
const { responseInitialValue: getPractitionerInit, endpoint: getPractitionerEndpoint } = getPractitioner;
const { responseInitialValue: getPractitionerByIdInit, endpoint: getPractitionerByIdEndpoint } = getPractitionerById;
const {
  responseInitialValue: getPractitionerByIdAppointmentTypeInit,
  endpoint: getPractitionerByIdAppointmentTypeEndpoint
} = getPractitionerByIdAppointmentType;
const {
  responseInitialValue: getPractitionerByIdFreeAppointmentsInit,
  endpoint: getPractitionerByIdFreeAppointmentsEndpoint
} = getPractitionerByIdFreeAppointments;
const { responseInitialValue: getSearchInit, endpoint: getSearchEndpoint } = getSearch;
const { responseInitialValue: getSearchProtectedInit, endpoint: getSearchProtectedEndpoint } = getSearchProtected;
const { responseInitialValue: getUpcomingAppointmentInit, endpoint: getUpcomingAppointmentEndpoint } =
  getUpcomingAppointment;
const { responseInitialValue: getUpcomingAppointmentByIdInit, endpoint: getUpcomingAppointmentByIdEndpoint } =
  getUpcomingAppointmentById;
const {
  responseInitialValue: postUpcomingAppointmentByIdCancelConfirmInit,
  endpoint: postUpcomingAppointmentByIdCancelConfirmEndpoint
} = postUpcomingAppointmentByIdCancelConfirm;
const {
  responseInitialValue: postUpcomingAppointmentByIdCancelInit,
  endpoint: postUpcomingAppointmentByIdCancelEndpoint
} = postUpcomingAppointmentByIdCancel;
const {
  responseInitialValue: postUpcomingAppointmentByIdCheckInInit,
  endpoint: postUpcomingAppointmentByIdCheckInEndpoint
} = postUpcomingAppointmentByIdCheckIn;

/**
 *
 * @description 请求返回的默认数据
 */
export const resInitPatient = {
  postAuthForgetPassword: postAuthForgetPasswordInit,
  postAuthGuest: postAuthGuestInit,
  postAuthLogin: postAuthLoginInit,
  postAuthLogoutAll: postAuthLogoutAllInit,
  postAuthLogout: postAuthLogoutInit,
  postAuthMessagingToken: postAuthMessagingTokenInit,
  putAuthMessagingTokenById: putAuthMessagingTokenByIdInit,
  deleteAuthMessagingTokenById: deleteAuthMessagingTokenByIdInit,
  postAuthRegister: postAuthRegisterInit,
  postAuthRegisterValidateEmail: postAuthRegisterValidateEmailInit,
  getAuthUserProfile: getAuthUserProfileInit,
  putAuthUserProfile: putAuthUserProfileInit,
  postContactUs: postContactUsInit,
  getFamilyMember: getFamilyMemberInit,
  postFamilyMember: postFamilyMemberInit,
  getFamilyMemberById: getFamilyMemberByIdInit,
  putFamilyMemberById: putFamilyMemberByIdInit,
  patchFamilyMemberById: patchFamilyMemberByIdInit,
  deleteFamilyMemberById: deleteFamilyMemberByIdInit,
  getHistoricalAppointment: getHistoricalAppointmentInit,
  getMyCareTeamPractice: getMyCareTeamPracticeInit,
  postMyCareTeamPracticeAddByPracticePk: postMyCareTeamPracticeAddByPracticePkInit,
  postMyCareTeamPracticeRemoveByPracticePk: postMyCareTeamPracticeRemoveByPracticePkInit,
  getMyCareTeamPractitioner: getMyCareTeamPractitionerInit,
  postMyCareTeamPractitionerAddByPractitionerPk: postMyCareTeamPractitionerAddByPractitionerPkInit,
  postMyCareTeamPractitionerRemoveByPractitionerPk: postMyCareTeamPractitionerRemoveByPractitionerPkInit,
  getPayment: getPaymentInit,
  postPayment: postPaymentInit,
  deletePaymentById: deletePaymentByIdInit,
  getPractice: getPracticeInit,
  getPracticeById: getPracticeByIdInit,
  getPracticeByIdAppointmentType: getPracticeByIdAppointmentTypeInit,
  getPractitioner: getPractitionerInit,
  getPractitionerById: getPractitionerByIdInit,
  getPractitionerByIdAppointmentType: getPractitionerByIdAppointmentTypeInit,
  getPractitionerByIdFreeAppointments: getPractitionerByIdFreeAppointmentsInit,
  getSearch: getSearchInit,
  getSearchProtected: getSearchProtectedInit,
  getUpcomingAppointment: getUpcomingAppointmentInit,
  getUpcomingAppointmentById: getUpcomingAppointmentByIdInit,
  postUpcomingAppointmentByIdCancelConfirm: postUpcomingAppointmentByIdCancelConfirmInit,
  postUpcomingAppointmentByIdCancel: postUpcomingAppointmentByIdCancelInit,
  postUpcomingAppointmentByIdCheckIn: postUpcomingAppointmentByIdCheckInInit
};

/**
 *
 * @description RTK Query版本的接口请求
 */
const endpoints = pontApis.injectEndpoints({
  overrideExisting: true, // 若有命名重复的，直接覆盖
  endpoints: (builder) => ({
    Doctors_Patient_postAuthForgetPassword: postAuthForgetPasswordEndpoint(builder),

    Doctors_Patient_postAuthGuest: postAuthGuestEndpoint(builder),

    Doctors_Patient_postAuthLogin: postAuthLoginEndpoint(builder),

    Doctors_Patient_postAuthLogoutAll: postAuthLogoutAllEndpoint(builder),

    Doctors_Patient_postAuthLogout: postAuthLogoutEndpoint(builder),

    Doctors_Patient_postAuthMessagingToken: postAuthMessagingTokenEndpoint(builder),

    Doctors_Patient_putAuthMessagingTokenById: putAuthMessagingTokenByIdEndpoint(builder),

    Doctors_Patient_deleteAuthMessagingTokenById: deleteAuthMessagingTokenByIdEndpoint(builder),

    Doctors_Patient_postAuthRegister: postAuthRegisterEndpoint(builder),

    Doctors_Patient_postAuthRegisterValidateEmail: postAuthRegisterValidateEmailEndpoint(builder),

    Doctors_Patient_getAuthUserProfile: getAuthUserProfileEndpoint(builder),

    Doctors_Patient_putAuthUserProfile: putAuthUserProfileEndpoint(builder),

    Doctors_Patient_postContactUs: postContactUsEndpoint(builder),

    Doctors_Patient_getFamilyMember: getFamilyMemberEndpoint(builder),

    Doctors_Patient_postFamilyMember: postFamilyMemberEndpoint(builder),

    Doctors_Patient_getFamilyMemberById: getFamilyMemberByIdEndpoint(builder),

    Doctors_Patient_putFamilyMemberById: putFamilyMemberByIdEndpoint(builder),

    Doctors_Patient_patchFamilyMemberById: patchFamilyMemberByIdEndpoint(builder),

    Doctors_Patient_deleteFamilyMemberById: deleteFamilyMemberByIdEndpoint(builder),

    Doctors_Patient_getHistoricalAppointment: getHistoricalAppointmentEndpoint(builder),

    Doctors_Patient_getMyCareTeamPractice: getMyCareTeamPracticeEndpoint(builder),

    Doctors_Patient_postMyCareTeamPracticeAddByPracticePk: postMyCareTeamPracticeAddByPracticePkEndpoint(builder),

    Doctors_Patient_postMyCareTeamPracticeRemoveByPracticePk: postMyCareTeamPracticeRemoveByPracticePkEndpoint(builder),

    Doctors_Patient_getMyCareTeamPractitioner: getMyCareTeamPractitionerEndpoint(builder),

    Doctors_Patient_postMyCareTeamPractitionerAddByPractitionerPk:
      postMyCareTeamPractitionerAddByPractitionerPkEndpoint(builder),

    Doctors_Patient_postMyCareTeamPractitionerRemoveByPractitionerPk:
      postMyCareTeamPractitionerRemoveByPractitionerPkEndpoint(builder),

    Doctors_Patient_getPayment: getPaymentEndpoint(builder),

    Doctors_Patient_postPayment: postPaymentEndpoint(builder),

    Doctors_Patient_deletePaymentById: deletePaymentByIdEndpoint(builder),

    Doctors_Patient_getPractice: getPracticeEndpoint(builder),

    Doctors_Patient_getPracticeById: getPracticeByIdEndpoint(builder),

    Doctors_Patient_getPracticeByIdAppointmentType: getPracticeByIdAppointmentTypeEndpoint(builder),

    Doctors_Patient_getPractitioner: getPractitionerEndpoint(builder),

    Doctors_Patient_getPractitionerById: getPractitionerByIdEndpoint(builder),

    Doctors_Patient_getPractitionerByIdAppointmentType: getPractitionerByIdAppointmentTypeEndpoint(builder),

    Doctors_Patient_getPractitionerByIdFreeAppointments: getPractitionerByIdFreeAppointmentsEndpoint(builder),

    Doctors_Patient_getSearch: getSearchEndpoint(builder),

    Doctors_Patient_getSearchProtected: getSearchProtectedEndpoint(builder),

    Doctors_Patient_getUpcomingAppointment: getUpcomingAppointmentEndpoint(builder),

    Doctors_Patient_getUpcomingAppointmentById: getUpcomingAppointmentByIdEndpoint(builder),

    Doctors_Patient_postUpcomingAppointmentByIdCancelConfirm: postUpcomingAppointmentByIdCancelConfirmEndpoint(builder),

    Doctors_Patient_postUpcomingAppointmentByIdCancel: postUpcomingAppointmentByIdCancelEndpoint(builder),

    Doctors_Patient_postUpcomingAppointmentByIdCheckIn: postUpcomingAppointmentByIdCheckInEndpoint(builder)
  })
});

export const apiPatient = {
  /**
   * @description useMutation Forget password api view
   * @description 请求路径: POST /api/patient/auth/forget-password/
   * @example
   * const [postAuthForgetPasswordTrigger, postAuthForgetPasswordResult] = apiPatient.usePostAuthForgetPasswordMutation();
   */
  usePostAuthForgetPasswordMutation: endpoints.useDoctors_Patient_postAuthForgetPasswordMutation,

  /**
   * @description useMutation guest booking
   * @description 请求路径: POST /api/patient/auth/guest/
   * @example
   * const [postAuthGuestTrigger, postAuthGuestResult] = apiPatient.usePostAuthGuestMutation();
   */
  usePostAuthGuestMutation: endpoints.useDoctors_Patient_postAuthGuestMutation,

  /**
   * @description useMutation Patient user login api
   * @description 请求路径: POST /api/patient/auth/login/
   * @example
   * const [postAuthLoginTrigger, postAuthLoginResult] = apiPatient.usePostAuthLoginMutation();
   */
  usePostAuthLoginMutation: endpoints.useDoctors_Patient_postAuthLoginMutation,

  /**
   * @description useMutation Logout and delete all tokens that relate to this user.
   * @description 请求路径: POST /api/patient/auth/logout-all/
   * @example
   * const [postAuthLogoutAllTrigger, postAuthLogoutAllResult] = apiPatient.usePostAuthLogoutAllMutation();
   */
  usePostAuthLogoutAllMutation: endpoints.useDoctors_Patient_postAuthLogoutAllMutation,

  /**
   * @description useMutation Logout and delete current login token.
   * @description 请求路径: POST /api/patient/auth/logout/
   * @example
   * const [postAuthLogoutTrigger, postAuthLogoutResult] = apiPatient.usePostAuthLogoutMutation();
   */
  usePostAuthLogoutMutation: endpoints.useDoctors_Patient_postAuthLogoutMutation,

  /**
   * @description useMutation Firebase messaging token api
   * @description 请求路径: POST /api/patient/auth/messaging/token/
   * @example
   * const [postAuthMessagingTokenTrigger, postAuthMessagingTokenResult] = apiPatient.usePostAuthMessagingTokenMutation();
   */
  usePostAuthMessagingTokenMutation: endpoints.useDoctors_Patient_postAuthMessagingTokenMutation,

  /**
   * @description useMutation Firebase messaging token api
   * @description 请求路径: PUT /api/patient/auth/messaging/token/{id}/
   * @example
   * const [putAuthMessagingTokenByIdTrigger, putAuthMessagingTokenByIdResult] = apiPatient.usePutAuthMessagingTokenByIdMutation();
   */
  usePutAuthMessagingTokenByIdMutation: endpoints.useDoctors_Patient_putAuthMessagingTokenByIdMutation,

  /**
   * @description useMutation Firebase messaging token api
   * @description 请求路径: DELETE /api/patient/auth/messaging/token/{id}/
   * @example
   * const [deleteAuthMessagingTokenByIdTrigger, deleteAuthMessagingTokenByIdResult] = apiPatient.useDeleteAuthMessagingTokenByIdMutation();
   */
  useDeleteAuthMessagingTokenByIdMutation: endpoints.useDoctors_Patient_deleteAuthMessagingTokenByIdMutation,

  /**
   * @description useMutation patient user login api
   * @description 请求路径: POST /api/patient/auth/register/
   * @example
   * const [postAuthRegisterTrigger, postAuthRegisterResult] = apiPatient.usePostAuthRegisterMutation();
   */
  usePostAuthRegisterMutation: endpoints.useDoctors_Patient_postAuthRegisterMutation,

  /**
   * @description useMutation patient user login api
   * @description 请求路径: POST /api/patient/auth/register/validate-email/
   * @example
   * const [postAuthRegisterValidateEmailTrigger, postAuthRegisterValidateEmailResult] = apiPatient.usePostAuthRegisterValidateEmailMutation();
   */
  usePostAuthRegisterValidateEmailMutation: endpoints.useDoctors_Patient_postAuthRegisterValidateEmailMutation,

  /**
   * @description useQuery patient user profile api
   * @description 请求路径: GET /api/patient/auth/user/profile/
   * @example
   * const getAuthUserProfileResult = apiPatient.useGetAuthUserProfileQuery();
   */
  useGetAuthUserProfileQuery: endpoints.useDoctors_Patient_getAuthUserProfileQuery,

  /**
   * @description useLazyQuery patient user profile api
   * @description 请求路径: GET /api/patient/auth/user/profile/
   * @example
   * const [getAuthUserProfileTrigger, getAuthUserProfileResult, getAuthUserProfileLastInfo] = apiPatient.useLazyGetAuthUserProfileQuery();
   */
  useLazyGetAuthUserProfileQuery: endpoints.useLazyDoctors_Patient_getAuthUserProfileQuery,

  /**
   * @description useMutation patient user profile api
   * @description 请求路径: PUT /api/patient/auth/user/profile/
   * @example
   * const [putAuthUserProfileTrigger, putAuthUserProfileResult] = apiPatient.usePutAuthUserProfileMutation();
   */
  usePutAuthUserProfileMutation: endpoints.useDoctors_Patient_putAuthUserProfileMutation,

  /**
   * @description useMutation contact us api view
   * @description 请求路径: POST /api/patient/contact-us/
   * @example
   * const [postContactUsTrigger, postContactUsResult] = apiPatient.usePostContactUsMutation();
   */
  usePostContactUsMutation: endpoints.useDoctors_Patient_postContactUsMutation,

  /**
   * @description useQuery patient family member api
   * @description 请求路径: GET /api/patient/family-member/
   * @example
   * const getFamilyMemberResult = apiPatient.useGetFamilyMemberQuery();
   */
  useGetFamilyMemberQuery: endpoints.useDoctors_Patient_getFamilyMemberQuery,

  /**
   * @description useLazyQuery patient family member api
   * @description 请求路径: GET /api/patient/family-member/
   * @example
   * const [getFamilyMemberTrigger, getFamilyMemberResult, getFamilyMemberLastInfo] = apiPatient.useLazyGetFamilyMemberQuery();
   */
  useLazyGetFamilyMemberQuery: endpoints.useLazyDoctors_Patient_getFamilyMemberQuery,

  /**
   * @description useMutation patient family member api
   * @description 请求路径: POST /api/patient/family-member/
   * @example
   * const [postFamilyMemberTrigger, postFamilyMemberResult] = apiPatient.usePostFamilyMemberMutation();
   */
  usePostFamilyMemberMutation: endpoints.useDoctors_Patient_postFamilyMemberMutation,

  /**
   * @description useQuery patient family member api
   * @description 请求路径: GET /api/patient/family-member/{id}/
   * @example
   * const getFamilyMemberByIdResult = apiPatient.useGetFamilyMemberByIdQuery();
   */
  useGetFamilyMemberByIdQuery: endpoints.useDoctors_Patient_getFamilyMemberByIdQuery,

  /**
   * @description useLazyQuery patient family member api
   * @description 请求路径: GET /api/patient/family-member/{id}/
   * @example
   * const [getFamilyMemberByIdTrigger, getFamilyMemberByIdResult, getFamilyMemberByIdLastInfo] = apiPatient.useLazyGetFamilyMemberByIdQuery();
   */
  useLazyGetFamilyMemberByIdQuery: endpoints.useLazyDoctors_Patient_getFamilyMemberByIdQuery,

  /**
   * @description useMutation patient family member api
   * @description 请求路径: PUT /api/patient/family-member/{id}/
   * @example
   * const [putFamilyMemberByIdTrigger, putFamilyMemberByIdResult] = apiPatient.usePutFamilyMemberByIdMutation();
   */
  usePutFamilyMemberByIdMutation: endpoints.useDoctors_Patient_putFamilyMemberByIdMutation,

  /**
   * @description useMutation patient family member api
   * @description 请求路径: PATCH /api/patient/family-member/{id}/
   * @example
   * const [patchFamilyMemberByIdTrigger, patchFamilyMemberByIdResult] = apiPatient.usePatchFamilyMemberByIdMutation();
   */
  usePatchFamilyMemberByIdMutation: endpoints.useDoctors_Patient_patchFamilyMemberByIdMutation,

  /**
   * @description useMutation patient family member api
   * @description 请求路径: DELETE /api/patient/family-member/{id}/
   * @example
   * const [deleteFamilyMemberByIdTrigger, deleteFamilyMemberByIdResult] = apiPatient.useDeleteFamilyMemberByIdMutation();
   */
  useDeleteFamilyMemberByIdMutation: endpoints.useDoctors_Patient_deleteFamilyMemberByIdMutation,

  /**
   * @description useQuery patient history appointment api
   * @description 请求路径: GET /api/patient/historical-appointment/
   * @example
   * const getHistoricalAppointmentResult = apiPatient.useGetHistoricalAppointmentQuery();
   */
  useGetHistoricalAppointmentQuery: endpoints.useDoctors_Patient_getHistoricalAppointmentQuery,

  /**
   * @description useLazyQuery patient history appointment api
   * @description 请求路径: GET /api/patient/historical-appointment/
   * @example
   * const [getHistoricalAppointmentTrigger, getHistoricalAppointmentResult, getHistoricalAppointmentLastInfo] = apiPatient.useLazyGetHistoricalAppointmentQuery();
   */
  useLazyGetHistoricalAppointmentQuery: endpoints.useLazyDoctors_Patient_getHistoricalAppointmentQuery,

  /**
   * @description useQuery undefined
   * @description 请求路径: GET /api/patient/my-care-team/practice/
   * @example
   * const getMyCareTeamPracticeResult = apiPatient.useGetMyCareTeamPracticeQuery();
   */
  useGetMyCareTeamPracticeQuery: endpoints.useDoctors_Patient_getMyCareTeamPracticeQuery,

  /**
   * @description useLazyQuery undefined
   * @description 请求路径: GET /api/patient/my-care-team/practice/
   * @example
   * const [getMyCareTeamPracticeTrigger, getMyCareTeamPracticeResult, getMyCareTeamPracticeLastInfo] = apiPatient.useLazyGetMyCareTeamPracticeQuery();
   */
  useLazyGetMyCareTeamPracticeQuery: endpoints.useLazyDoctors_Patient_getMyCareTeamPracticeQuery,

  /**
   * @description useMutation undefined
   * @description 请求路径: POST /api/patient/my-care-team/practice/add/{practicePk}/
   * @example
   * const [postMyCareTeamPracticeAddByPracticePkTrigger, postMyCareTeamPracticeAddByPracticePkResult] = apiPatient.usePostMyCareTeamPracticeAddByPracticePkMutation();
   */
  usePostMyCareTeamPracticeAddByPracticePkMutation:
    endpoints.useDoctors_Patient_postMyCareTeamPracticeAddByPracticePkMutation,

  /**
   * @description useMutation undefined
   * @description 请求路径: POST /api/patient/my-care-team/practice/remove/{practicePk}/
   * @example
   * const [postMyCareTeamPracticeRemoveByPracticePkTrigger, postMyCareTeamPracticeRemoveByPracticePkResult] = apiPatient.usePostMyCareTeamPracticeRemoveByPracticePkMutation();
   */
  usePostMyCareTeamPracticeRemoveByPracticePkMutation:
    endpoints.useDoctors_Patient_postMyCareTeamPracticeRemoveByPracticePkMutation,

  /**
   * @description useQuery undefined
   * @description 请求路径: GET /api/patient/my-care-team/practitioner/
   * @example
   * const getMyCareTeamPractitionerResult = apiPatient.useGetMyCareTeamPractitionerQuery();
   */
  useGetMyCareTeamPractitionerQuery: endpoints.useDoctors_Patient_getMyCareTeamPractitionerQuery,

  /**
   * @description useLazyQuery undefined
   * @description 请求路径: GET /api/patient/my-care-team/practitioner/
   * @example
   * const [getMyCareTeamPractitionerTrigger, getMyCareTeamPractitionerResult, getMyCareTeamPractitionerLastInfo] = apiPatient.useLazyGetMyCareTeamPractitionerQuery();
   */
  useLazyGetMyCareTeamPractitionerQuery: endpoints.useLazyDoctors_Patient_getMyCareTeamPractitionerQuery,

  /**
   * @description useMutation undefined
   * @description 请求路径: POST /api/patient/my-care-team/practitioner/add/{practitionerPk}/
   * @example
   * const [postMyCareTeamPractitionerAddByPractitionerPkTrigger, postMyCareTeamPractitionerAddByPractitionerPkResult] = apiPatient.usePostMyCareTeamPractitionerAddByPractitionerPkMutation();
   */
  usePostMyCareTeamPractitionerAddByPractitionerPkMutation:
    endpoints.useDoctors_Patient_postMyCareTeamPractitionerAddByPractitionerPkMutation,

  /**
   * @description useMutation undefined
   * @description 请求路径: POST /api/patient/my-care-team/practitioner/remove/{practitionerPk}/
   * @example
   * const [postMyCareTeamPractitionerRemoveByPractitionerPkTrigger, postMyCareTeamPractitionerRemoveByPractitionerPkResult] = apiPatient.usePostMyCareTeamPractitionerRemoveByPractitionerPkMutation();
   */
  usePostMyCareTeamPractitionerRemoveByPractitionerPkMutation:
    endpoints.useDoctors_Patient_postMyCareTeamPractitionerRemoveByPractitionerPkMutation,

  /**
   * @description useQuery patient payment card api
   * @description 请求路径: GET /api/patient/payment/
   * @example
   * const getPaymentResult = apiPatient.useGetPaymentQuery();
   */
  useGetPaymentQuery: endpoints.useDoctors_Patient_getPaymentQuery,

  /**
   * @description useLazyQuery patient payment card api
   * @description 请求路径: GET /api/patient/payment/
   * @example
   * const [getPaymentTrigger, getPaymentResult, getPaymentLastInfo] = apiPatient.useLazyGetPaymentQuery();
   */
  useLazyGetPaymentQuery: endpoints.useLazyDoctors_Patient_getPaymentQuery,

  /**
   * @description useMutation post action
   * @description 请求路径: POST /api/patient/payment/
   * @example
   * const [postPaymentTrigger, postPaymentResult] = apiPatient.usePostPaymentMutation();
   */
  usePostPaymentMutation: endpoints.useDoctors_Patient_postPaymentMutation,

  /**
   * @description useMutation patient payment card api
   * @description 请求路径: DELETE /api/patient/payment/{id}/
   * @example
   * const [deletePaymentByIdTrigger, deletePaymentByIdResult] = apiPatient.useDeletePaymentByIdMutation();
   */
  useDeletePaymentByIdMutation: endpoints.useDoctors_Patient_deletePaymentByIdMutation,

  /**
   * @description useQuery practice data view set
   * @description 请求路径: GET /api/patient/practice/
   * @example
   * const getPracticeResult = apiPatient.useGetPracticeQuery();
   */
  useGetPracticeQuery: endpoints.useDoctors_Patient_getPracticeQuery,

  /**
   * @description useLazyQuery practice data view set
   * @description 请求路径: GET /api/patient/practice/
   * @example
   * const [getPracticeTrigger, getPracticeResult, getPracticeLastInfo] = apiPatient.useLazyGetPracticeQuery();
   */
  useLazyGetPracticeQuery: endpoints.useLazyDoctors_Patient_getPracticeQuery,

  /**
   * @description useQuery practice data view set
   * @description 请求路径: GET /api/patient/practice/{id}/
   * @example
   * const getPracticeByIdResult = apiPatient.useGetPracticeByIdQuery();
   */
  useGetPracticeByIdQuery: endpoints.useDoctors_Patient_getPracticeByIdQuery,

  /**
   * @description useLazyQuery practice data view set
   * @description 请求路径: GET /api/patient/practice/{id}/
   * @example
   * const [getPracticeByIdTrigger, getPracticeByIdResult, getPracticeByIdLastInfo] = apiPatient.useLazyGetPracticeByIdQuery();
   */
  useLazyGetPracticeByIdQuery: endpoints.useLazyDoctors_Patient_getPracticeByIdQuery,

  /**
   * @description useQuery practice data view set
   * @description 请求路径: GET /api/patient/practice/{id}/appointment-type/
   * @example
   * const getPracticeByIdAppointmentTypeResult = apiPatient.useGetPracticeByIdAppointmentTypeQuery();
   */
  useGetPracticeByIdAppointmentTypeQuery: endpoints.useDoctors_Patient_getPracticeByIdAppointmentTypeQuery,

  /**
   * @description useLazyQuery practice data view set
   * @description 请求路径: GET /api/patient/practice/{id}/appointment-type/
   * @example
   * const [getPracticeByIdAppointmentTypeTrigger, getPracticeByIdAppointmentTypeResult, getPracticeByIdAppointmentTypeLastInfo] = apiPatient.useLazyGetPracticeByIdAppointmentTypeQuery();
   */
  useLazyGetPracticeByIdAppointmentTypeQuery: endpoints.useLazyDoctors_Patient_getPracticeByIdAppointmentTypeQuery,

  /**
   * @description useQuery practice data view set
   * @description 请求路径: GET /api/patient/practitioner/
   * @example
   * const getPractitionerResult = apiPatient.useGetPractitionerQuery();
   */
  useGetPractitionerQuery: endpoints.useDoctors_Patient_getPractitionerQuery,

  /**
   * @description useLazyQuery practice data view set
   * @description 请求路径: GET /api/patient/practitioner/
   * @example
   * const [getPractitionerTrigger, getPractitionerResult, getPractitionerLastInfo] = apiPatient.useLazyGetPractitionerQuery();
   */
  useLazyGetPractitionerQuery: endpoints.useLazyDoctors_Patient_getPractitionerQuery,

  /**
   * @description useQuery practice data view set
   * @description 请求路径: GET /api/patient/practitioner/{id}/
   * @example
   * const getPractitionerByIdResult = apiPatient.useGetPractitionerByIdQuery();
   */
  useGetPractitionerByIdQuery: endpoints.useDoctors_Patient_getPractitionerByIdQuery,

  /**
   * @description useLazyQuery practice data view set
   * @description 请求路径: GET /api/patient/practitioner/{id}/
   * @example
   * const [getPractitionerByIdTrigger, getPractitionerByIdResult, getPractitionerByIdLastInfo] = apiPatient.useLazyGetPractitionerByIdQuery();
   */
  useLazyGetPractitionerByIdQuery: endpoints.useLazyDoctors_Patient_getPractitionerByIdQuery,

  /**
   * @description useQuery practice data view set
   * @description 请求路径: GET /api/patient/practitioner/{id}/appointment-type/
   * @example
   * const getPractitionerByIdAppointmentTypeResult = apiPatient.useGetPractitionerByIdAppointmentTypeQuery();
   */
  useGetPractitionerByIdAppointmentTypeQuery: endpoints.useDoctors_Patient_getPractitionerByIdAppointmentTypeQuery,

  /**
   * @description useLazyQuery practice data view set
   * @description 请求路径: GET /api/patient/practitioner/{id}/appointment-type/
   * @example
   * const [getPractitionerByIdAppointmentTypeTrigger, getPractitionerByIdAppointmentTypeResult, getPractitionerByIdAppointmentTypeLastInfo] = apiPatient.useLazyGetPractitionerByIdAppointmentTypeQuery();
   */
  useLazyGetPractitionerByIdAppointmentTypeQuery:
    endpoints.useLazyDoctors_Patient_getPractitionerByIdAppointmentTypeQuery,

  /**
   * @description useQuery practice data view set
   * @description 请求路径: GET /api/patient/practitioner/{id}/free-appointments/
   * @example
   * const getPractitionerByIdFreeAppointmentsResult = apiPatient.useGetPractitionerByIdFreeAppointmentsQuery();
   */
  useGetPractitionerByIdFreeAppointmentsQuery: endpoints.useDoctors_Patient_getPractitionerByIdFreeAppointmentsQuery,

  /**
   * @description useLazyQuery practice data view set
   * @description 请求路径: GET /api/patient/practitioner/{id}/free-appointments/
   * @example
   * const [getPractitionerByIdFreeAppointmentsTrigger, getPractitionerByIdFreeAppointmentsResult, getPractitionerByIdFreeAppointmentsLastInfo] = apiPatient.useLazyGetPractitionerByIdFreeAppointmentsQuery();
   */
  useLazyGetPractitionerByIdFreeAppointmentsQuery:
    endpoints.useLazyDoctors_Patient_getPractitionerByIdFreeAppointmentsQuery,

  /**
   * @description useQuery Public search API
   * @description 请求路径: GET /api/patient/search/
   * @example
   * const getSearchResult = apiPatient.useGetSearchQuery();
   */
  useGetSearchQuery: endpoints.useDoctors_Patient_getSearchQuery,

  /**
   * @description useLazyQuery Public search API
   * @description 请求路径: GET /api/patient/search/
   * @example
   * const [getSearchTrigger, getSearchResult, getSearchLastInfo] = apiPatient.useLazyGetSearchQuery();
   */
  useLazyGetSearchQuery: endpoints.useLazyDoctors_Patient_getSearchQuery,

  /**
   * @description useQuery Protected Search API
   * @description 请求路径: GET /api/patient/search/protected/
   * @example
   * const getSearchProtectedResult = apiPatient.useGetSearchProtectedQuery();
   */
  useGetSearchProtectedQuery: endpoints.useDoctors_Patient_getSearchProtectedQuery,

  /**
   * @description useLazyQuery Protected Search API
   * @description 请求路径: GET /api/patient/search/protected/
   * @example
   * const [getSearchProtectedTrigger, getSearchProtectedResult, getSearchProtectedLastInfo] = apiPatient.useLazyGetSearchProtectedQuery();
   */
  useLazyGetSearchProtectedQuery: endpoints.useLazyDoctors_Patient_getSearchProtectedQuery,

  /**
   * @description useQuery patient appointment api
   * @description 请求路径: GET /api/patient/upcoming-appointment/
   * @example
   * const getUpcomingAppointmentResult = apiPatient.useGetUpcomingAppointmentQuery();
   */
  useGetUpcomingAppointmentQuery: endpoints.useDoctors_Patient_getUpcomingAppointmentQuery,

  /**
   * @description useLazyQuery patient appointment api
   * @description 请求路径: GET /api/patient/upcoming-appointment/
   * @example
   * const [getUpcomingAppointmentTrigger, getUpcomingAppointmentResult, getUpcomingAppointmentLastInfo] = apiPatient.useLazyGetUpcomingAppointmentQuery();
   */
  useLazyGetUpcomingAppointmentQuery: endpoints.useLazyDoctors_Patient_getUpcomingAppointmentQuery,

  /**
   * @description useQuery patient appointment api
   * @description 请求路径: GET /api/patient/upcoming-appointment/{id}/
   * @example
   * const getUpcomingAppointmentByIdResult = apiPatient.useGetUpcomingAppointmentByIdQuery();
   */
  useGetUpcomingAppointmentByIdQuery: endpoints.useDoctors_Patient_getUpcomingAppointmentByIdQuery,

  /**
   * @description useLazyQuery patient appointment api
   * @description 请求路径: GET /api/patient/upcoming-appointment/{id}/
   * @example
   * const [getUpcomingAppointmentByIdTrigger, getUpcomingAppointmentByIdResult, getUpcomingAppointmentByIdLastInfo] = apiPatient.useLazyGetUpcomingAppointmentByIdQuery();
   */
  useLazyGetUpcomingAppointmentByIdQuery: endpoints.useLazyDoctors_Patient_getUpcomingAppointmentByIdQuery,

  /**
   * @description useMutation patient appointment api
   * @description 请求路径: POST /api/patient/upcoming-appointment/{id}/cancel-confirm/
   * @example
   * const [postUpcomingAppointmentByIdCancelConfirmTrigger, postUpcomingAppointmentByIdCancelConfirmResult] = apiPatient.usePostUpcomingAppointmentByIdCancelConfirmMutation();
   */
  usePostUpcomingAppointmentByIdCancelConfirmMutation:
    endpoints.useDoctors_Patient_postUpcomingAppointmentByIdCancelConfirmMutation,

  /**
   * @description useMutation patient appointment api
   * @description 请求路径: POST /api/patient/upcoming-appointment/{id}/cancel/
   * @example
   * const [postUpcomingAppointmentByIdCancelTrigger, postUpcomingAppointmentByIdCancelResult] = apiPatient.usePostUpcomingAppointmentByIdCancelMutation();
   */
  usePostUpcomingAppointmentByIdCancelMutation: endpoints.useDoctors_Patient_postUpcomingAppointmentByIdCancelMutation,

  /**
   * @description useMutation check in an appointment
   * @description 请求路径: POST /api/patient/upcoming-appointment/{id}/check-in/
   * @example
   * const [postUpcomingAppointmentByIdCheckInTrigger, postUpcomingAppointmentByIdCheckInResult] = apiPatient.usePostUpcomingAppointmentByIdCheckInMutation();
   */
  usePostUpcomingAppointmentByIdCheckInMutation: endpoints.useDoctors_Patient_postUpcomingAppointmentByIdCheckInMutation
};

export {
  postAuthForgetPassword,
  postAuthGuest,
  postAuthLogin,
  postAuthLogoutAll,
  postAuthLogout,
  postAuthMessagingToken,
  putAuthMessagingTokenById,
  deleteAuthMessagingTokenById,
  postAuthRegister,
  postAuthRegisterValidateEmail,
  getAuthUserProfile,
  putAuthUserProfile,
  postContactUs,
  getFamilyMember,
  postFamilyMember,
  getFamilyMemberById,
  putFamilyMemberById,
  patchFamilyMemberById,
  deleteFamilyMemberById,
  getHistoricalAppointment,
  getMyCareTeamPractice,
  postMyCareTeamPracticeAddByPracticePk,
  postMyCareTeamPracticeRemoveByPracticePk,
  getMyCareTeamPractitioner,
  postMyCareTeamPractitionerAddByPractitionerPk,
  postMyCareTeamPractitionerRemoveByPractitionerPk,
  getPayment,
  postPayment,
  deletePaymentById,
  getPractice,
  getPracticeById,
  getPracticeByIdAppointmentType,
  getPractitioner,
  getPractitionerById,
  getPractitionerByIdAppointmentType,
  getPractitionerByIdFreeAppointments,
  getSearch,
  getSearchProtected,
  getUpcomingAppointment,
  getUpcomingAppointmentById,
  postUpcomingAppointmentByIdCancelConfirm,
  postUpcomingAppointmentByIdCancel,
  postUpcomingAppointmentByIdCheckIn
};
