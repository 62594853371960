import {
  CaretDownOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  CaretUpOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Button, List, Table, Tag } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import usePhion from 'src/hook/usePhion';

interface Props {
  fourDate: dayjs.Dayjs;
  doctorsInfo: any;
  fourDataList: any[];
  handleFour: (a: any, b: any, c: any) => void;
  setFourDate: (a: dayjs.Dayjs) => void;
  setFourDataList: (a: any[]) => void;
}

const Four: React.FC<Props> = props => {
  const { fourDataList, fourDate, doctorsInfo, handleFour, setFourDate, setFourDataList } = props;
  const [isMobile] = usePhion();
  const getData = appointments => {
    if (!appointments) {
      return [];
    }
    const newMap = _.sortBy(_.keys(appointments));
    let maxLingth = 0;
    _.map(newMap, i => {
      const lingth = appointments[i]?.length;
      if (lingth > maxLingth) {
        maxLingth = lingth;
      }
    });

    const mapList = _.map(_.range(maxLingth), index => {
      const mapObj = {};
      _.map(newMap, key => {
        mapObj[key] = appointments[key]?.[index] || '';
      });
      return mapObj;
    });
    return mapList;
  };

  const getColumns = value => {
    const appointments = value.appointments;
    const newMap = _.keys(appointments).map(item => {
      const week = dayjs(item).format('ddd');
      const day = dayjs(item).format('DD MMM');
      return {
        title: (
          <div className="table-title">
            <div>{week}</div>
            <div>{day}</div>
          </div>
        ),
        dataIndex: item,
        render: text =>
          text && (
            <Tag
              bordered={false}
              color="#ffeb3b"
              onClick={() => {
                text && handleFour(value, item, text);
              }}
            >
              {text}
            </Tag>
          ),
      };
    });
    return _.sortBy(newMap, 'dataIndex');
  };

  const disabledForuBtn = React.useMemo(() => {
    return fourDate && fourDate < dayjs().endOf('day');
  }, [fourDate]);

  const emptyText = React.useMemo(
    () => value => {
      if (value?.nextAvailableAppointment) {
        return (
          <div className="lock-time">
            <Button
              type="primary"
              onClick={() => {
                setFourDate(dayjs(value?.nextAvailableAppointment));
              }}
            >
              Next available：{dayjs(value?.nextAvailableAppointment).format('ddd, DD MMM')}
            </Button>
          </div>
        );
      }
      let content;
      if (isMobile) {
        content = (
          <a className="isMobile" href={`tel:${doctorsInfo?.phone}`}>
            {doctorsInfo?.phone}
          </a>
        );
      } else {
        content = <div style={{ marginTop: 5 }}>{doctorsInfo?.phone}</div>;
      }

      return (
        <div className="noData">
          <div className="nodata-con">
            <div>Call practice to book</div>
            {content}
          </div>
        </div>
      );
    },
    [doctorsInfo, isMobile, setFourDate],
  );

  return (
    <div className="four">
      <List
        rowKey="id"
        dataSource={fourDataList}
        renderItem={(item, index) => {
          const dataSource = getData(item.appointments);
          return (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar shape="square" size={64} src={item?.avatar} icon={<UserOutlined />} />}
                title={item.fullName}
                description={
                  <div>
                    <div style={{ fontSize: 14, minHeight: 20 }}>{item.tags}</div>
                    {item?.about && <About data={item?.about} />}
                  </div>
                }
              />

              <div className="list-table">
                <div className="table-item">
                  <Button
                    className="btn-title"
                    type="primary"
                    disabled={disabledForuBtn}
                    style={{ marginRight: 6 }}
                    icon={<CaretLeftOutlined />}
                    onClick={() => {
                      const startDate = fourDate.subtract(5, 'day');
                      setFourDate(startDate);
                    }}
                  />

                  <Table
                    columns={getColumns(item)}
                    dataSource={dataSource}
                    bordered
                    locale={{ emptyText: emptyText(item) }}
                    pagination={false}
                    scroll={{ y: item.open ? undefined : isMobile ? 174 : 200 }}
                  />
                  <Button
                    className="btn-title"
                    type="primary"
                    style={{ marginLeft: 6 }}
                    icon={<CaretRightOutlined />}
                    onClick={() => {
                      const startDate = fourDate.add(5, 'day');
                      setFourDate(startDate);
                    }}
                  />
                </div>
                {dataSource.length > 0 && (
                  <Button
                    className="btn-bottom"
                    type="primary"
                    icon={item.open ? <CaretUpOutlined /> : <CaretDownOutlined />}
                    onClick={() => {
                      const newData = _.map(fourDataList, items => {
                        if (items.id === item.id) {
                          return { ...items, open: !item.open };
                        }
                        return items;
                      });
                      setFourDataList(newData);
                    }}
                  >
                    see all times
                  </Button>
                )}
              </div>
            </List.Item>
          );
        }}
      />
    </div>
  );
};

function About({ data }) {
  const Ref = React.useRef<HTMLDivElement>();
  const [open, setOpen] = React.useState(false);
  const [showMore, setShowMore] = React.useState(false);
  React.useEffect(() => {
    const node = Ref.current;
    if (node?.clientHeight > 40) {
      setShowMore(true);
    } else {
      setShowMore(false);
    }
  }, [data]);
  return (
    <div className="about">
      <div className={`about-main ${open ? 'open' : 'guanbi'}`}>
        <div ref={Ref}>
          <div dangerouslySetInnerHTML={{ __html: data }} />
        </div>

        {!open && showMore && <div className="mack" />}
      </div>
      {showMore && <a onClick={() => setOpen(!open)}>{open ? 'Less' : 'More'}</a>}
    </div>
  );
}

export default Four;
