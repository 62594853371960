import { Breadcrumb, Button, DatePicker, Form, Input, message, Select, Spin } from 'antd';
import React, { useEffect } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Link, useParams } from 'react-router-dom';
import './Family.scss';
import { GenderList } from 'src/utils/Definition';
import { apiPatient } from 'src/services/doctors/mods/patient';
import { browserHistory } from 'src/router';
import usePhion from 'src/hook/usePhion';

const Family: React.FC = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const [isMobile] = usePhion();

  const [putFamilyMemberByIdTrigger, { isLoading }] = apiPatient.usePutFamilyMemberByIdMutation();
  const [postFamilyMemberTrigger, { isLoading: btnLoading }] = apiPatient.usePostFamilyMemberMutation();
  const [getFamilyMemberByIdTrigger, { isFetching }] = apiPatient.useLazyGetFamilyMemberByIdQuery();
  const onFinish = async value => {
    try {
      const bodyParams = { ...value, dob: value.dob?.format('DD/MM/YYYY') };
      if (id) {
        await putFamilyMemberByIdTrigger({ id, bodyParams } as any).unwrap();
      } else {
        await postFamilyMemberTrigger({ bodyParams }).unwrap();
      }
      message.success('Operation successful');
      browserHistory.push('/account/family');
    } catch (error) {}
  };

  useEffect(() => {
    if (id) {
      getFamilyMemberByIdTrigger({ id } as any)
        .unwrap()
        .then(res => {
          const value = { ...res, dob: dayjs(res?.dob, 'DD/MM/YYYY') };
          form.setFieldsValue(value);
        });
    }
  }, []);
  return (
    <div className="page-family-edit">
      <Breadcrumb style={{ marginBottom: 20 }}>
        <Breadcrumb.Item>
          <Link to="/account">Account</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/account/family">Family Menber</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{id ? 'Update' : 'Add'} Family Menber</Breadcrumb.Item>
      </Breadcrumb>
      <Spin spinning={isFetching}>
        <Form layout="vertical" form={form} onFinish={onFinish} style={{ maxWidth: 600, margin: '0 auto' }}>
          <Form.Item name="firstName" rules={[{ required: true, message: 'Please input your First Name!' }]}>
            <Input placeholder="First Name" />
          </Form.Item>
          <Form.Item name="surname" rules={[{ required: true, message: 'Please input your Surname!' }]}>
            <Input placeholder="Surname" />
          </Form.Item>
          <Form.Item name="dob" rules={[{ required: true, message: 'Please select Date of birth!' }]}>
            <DatePicker
              style={{ width: '100%' }}
              placeholder="Date of birth"
              format="DD/MM/YYYY"
              inputReadOnly={isMobile}
              disabledDate={current => {
                return current && current > dayjs().endOf('day');
              }}
            />
          </Form.Item>
          <Form.Item name="gender" rules={[{ required: true, message: 'Please select gender!' }]}>
            <Select style={{ width: '100%' }} placeholder="Select Gender" options={GenderList} />
          </Form.Item>

          <Form.Item>
            <Button
              loading={isLoading || btnLoading}
              type="primary"
              htmlType="submit"
              size="large"
              style={{ width: '100%' }}
            >
              SAVE
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default Family;
