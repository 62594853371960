import React from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Breadcrumb, Button, DatePicker, Form, Input, Select } from 'antd';
import { MailOutlined, LockFilled } from '@ant-design/icons';
import { GenderList } from 'src/utils/Definition';
import { apiPatient } from 'src/services/doctors/mods/patient';
import { DOC_SIGNIN } from 'src/utils/Appoint';
import { browserHistory, useSearchParams } from 'src/router';
import { Link } from 'react-router-dom';
import './Register.scss';
import usePhion from 'src/hook/usePhion';

const Register: React.FC = () => {
  const [form] = Form.useForm();
  const { practice } = useSearchParams();

  const [isMobile] = usePhion();

  const [postAuthRegisterTrigger, { isLoading }] = apiPatient.usePostAuthRegisterMutation();
  const onFinish = value => {
    const bodyParams = { ...value, dob: dayjs(value.dob).format('DD/MM/YYYY'), practiceId: practice };
    postAuthRegisterTrigger({ bodyParams })
      .unwrap()
      .then(() => {
        localStorage.setItem(DOC_SIGNIN, JSON.stringify(value));
        browserHistory.replace('/login');
      })
      .catch(res => {
        if (_.isObject(res?.message)) {
          const errors = _.keys(res?.message).map(item => ({
            name: item,
            value: form.getFieldValue(item),
            errors: res?.message[item],
          }));
          form.setFields(errors);
        }
      });
  };
  return (
    <div className="page-Register">
      <Breadcrumb style={{ marginBottom: 20 }}>
        <Breadcrumb.Item>
          <Link to="/login">Login</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Create an account</Breadcrumb.Item>
      </Breadcrumb>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          name="email"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ]}
        >
          <Input placeholder="E-mail" prefix={<MailOutlined />} />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
          hasFeedback
        >
          <Input.Password placeholder="Password" prefix={<LockFilled />} />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm Password" prefix={<LockFilled />} />
        </Form.Item>

        <Form.Item name="firstName" rules={[{ required: true, message: 'Please input your First Name!' }]}>
          <Input placeholder="First Name" />
        </Form.Item>
        <Form.Item name="surname" rules={[{ required: true, message: 'Please input your Surname!' }]}>
          <Input placeholder="Surname" />
        </Form.Item>
        <Form.Item name="dob" rules={[{ required: true, message: 'Please select Date of birth!' }]}>
          <DatePicker
            style={{ width: '100%' }}
            placeholder="Date of birth"
            format="DD/MM/YYYY"
            inputReadOnly={isMobile}
            disabledDate={current => {
              return current && current > dayjs().endOf('day');
            }}
          />
        </Form.Item>
        <Form.Item name="gender" rules={[{ required: true, message: 'Please select gender!' }]}>
          <Select style={{ width: '100%' }} placeholder="Select Gender" options={GenderList} />
        </Form.Item>
        <Form.Item
          name="mobile"
          rules={[
            { required: true, message: 'Please input Mobile' },
            {
              validator: (rule, value) => {
                if (value.length > 16) {
                  return Promise.reject(new Error('Please enter the correct phone number'));
                }
                return Promise.resolve();
              },
            },
            {
              pattern: /^\s*04\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*$/,
              message: 'Please enter the correct phone number',
            },
          ]}
        >
          <Input placeholder="Mobile" />
        </Form.Item>
        <Form.Item>
          <Button loading={isLoading} type="primary" htmlType="submit" size="large" style={{ width: '100%' }}>
            SIGN UP
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Register;
