import { Button, ConfigProvider, DatePicker, Form, Input, message } from 'antd';
import React, { useMemo } from 'react';
import { browserHistory } from 'src/router';
import { apiPatient } from 'src/services/doctors/mods/patient';
import dayjs from 'dayjs';
import './Payment.scss';
import usePhion from 'src/hook/usePhion';

const Payment: React.FC = () => {
  const [form] = Form.useForm();
  const [isMobile] = usePhion();
  // const card = Form.useWatch('card', form);
  // const date = Form.useWatch('date', form);
  // const holder = Form.useWatch('holder', form);

  // const cardDate = useMemo(() => {
  //   if (date?.length === 4) {
  //     const [a, b, c, d] = date;
  //     return `${a}${b}/${c}${d}`;
  //   }
  //   return '';
  // }, [date]);

  const [postPaymentTrigger, { isLoading }] = apiPatient.usePostPaymentMutation();

  const onFinish = value => {
    console.log(value);
    const bodyParams = { ...value, cardExpiry: value.cardExpiry.format('MM/YYYY') };
    postPaymentTrigger({ bodyParams })
      .unwrap()
      .then(() => {
        message.success('Operation successful');
      });
  };

  return (
    <div className="page-payment-edit">
      <Form form={form} className="form" onFinish={onFinish}>
        <Form.Item name="cardNumber" rules={[{ required: true, message: 'Input Card Number' }]}>
          <Input placeholder="Card Number" />
        </Form.Item>
        <div className="item">
          <Form.Item
            name="cardExpiry"
            style={{ flex: 0.8 }}
            rules={[{ required: true, message: 'Select Expired Date' }]}
          >
            <DatePicker
              style={{ width: '100%' }}
              picker="month"
              placeholder="Expiry"
              format={'MM/YYYY'}
              inputReadOnly={isMobile}
            />
          </Form.Item>

          <Form.Item
            name="cvv"
            rules={[
              { required: true, message: 'Input CVV' },
              { pattern: /^\d{3}$/, message: 'Please enter the correct CVV' },
            ]}
          >
            <Input type="password" placeholder="CVV" />
          </Form.Item>
        </div>
        <Form.Item name="cardHolder" rules={[{ required: true, message: 'Input Card Holder' }]}>
          <Input placeholder="Card Holder" />
        </Form.Item>
        <div className="save">
          <Button loading={isLoading} type="primary" htmlType="submit" size="large" style={{ margin: '0 auto' }}>
            Validate
          </Button>
        </div>
      </Form>

      <div className="back">
        <Button type="text" onClick={() => browserHistory.push('/account/payment')}>
          Back
        </Button>
      </div>
    </div>
  );
};

export default Payment;
