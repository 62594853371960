import { Button, Checkbox, Form, Input } from 'antd';
import React from 'react';
import qs from 'query-string';
import { MailOutlined, LockFilled } from '@ant-design/icons';
import { browserHistory } from 'src/router';
import { apiPatient } from 'src/services/doctors/mods/patient';
import { DOC_PRACTICE, DOC_SIGNIN, DOC_TOKEN, DOC_USINFO } from 'src/utils/Appoint';
import './index.scss';
import _ from 'lodash';

const Login: React.FC = () => {
  const [postAuthLoginTrigger, { isLoading }] = apiPatient.usePostAuthLoginMutation();
  const [form] = Form.useForm();
  const onFinish = value => {
    postAuthLoginTrigger({ bodyParams: value })
      .unwrap()
      .then(res => {
        localStorage.setItem(DOC_TOKEN, res.token);
        localStorage.setItem(DOC_USINFO, JSON.stringify(res.user));
        localStorage.setItem(DOC_SIGNIN, JSON.stringify(value));
        browserHistory.replace('book');
      })
      .catch(res => {
        if (_.isObject(res?.message)) {
          const errors = _.keys(res?.message).map(item => ({
            name: item,
            value: form.getFieldValue(item),
            errors: res?.message[item],
          }));
          form.setFields(errors);
        }
      });
  };
  const { practice } = qs.parse(window.location.search);

  React.useEffect(() => {
    practice && localStorage.setItem(DOC_PRACTICE, practice as any);
  }, [practice]);

  const signIn = localStorage.getItem(DOC_SIGNIN);

  const initialValues = signIn ? JSON.parse(signIn) : {};

  return (
    <Form name="normal_login" form={form} className="login-form" initialValues={initialValues} onFinish={onFinish}>
      <Form.Item name="email" rules={[{ required: true, message: 'Input E-mail' }]}>
        <Input placeholder="E-mail" prefix={<MailOutlined />} />
      </Form.Item>

      <Form.Item name="password" rules={[{ required: true, message: 'Input Password' }]}>
        <Input.Password placeholder="Password" prefix={<LockFilled />} />
      </Form.Item>
      <Form.Item>
        <a href={`/register?practice=${practice}`}>Create an account</a>

        <a className="login-form-forgot" href="/forgot">
          Forgot password
        </a>
      </Form.Item>

      <Form.Item>
        <Button loading={isLoading} type="primary" size="large" htmlType="submit" className="login-form-button">
          SIGN IN
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Login;
