class Address {
  /** addressLine1 */
  addressLine1 = '';

  /** addressLine2 */
  addressLine2 = '';

  /** addressLine3 */
  addressLine3 = '';

  /** postcode */
  postcode = '';

  /** state */
  state = undefined;

  /** suburb */
  suburb = '';
}

class AddressRequest {
  /** addressLine1 */
  addressLine1 = '';

  /** addressLine2 */
  addressLine2 = '';

  /** addressLine3 */
  addressLine3 = '';

  /** postcode */
  postcode = '';

  /** state */
  state = undefined;

  /** suburb */
  suburb = '';
}

class AppointmentTypeCategoryModel {
  /** appointmentTypes */
  appointmentTypes = [];

  /** name */
  name = '';

  /** pk */
  pk = undefined;

  /** slug */
  slug = '';
}

class AppointmentTypeCategoryModelRequest {
  /** appointmentTypes */
  appointmentTypes = [];

  /** name */
  name = '';
}

class AppointmentTypeCategoryNestedModel {
  /** displayName */
  displayName = '';

  /** pk */
  pk = undefined;
}

class AppointmentTypeCategoryNestedModelRequest {
  /** pk */
  pk = undefined;
}

class AppointmentTypeInline {
  /** id */
  id = undefined;

  /** name */
  name = '';
}

class AppointmentTypeInlineCategory {
  /** name */
  name = '';

  /** pk */
  pk = undefined;
}

class AppointmentTypeInlineCategoryRequest {
  /** pk */
  pk = undefined;
}

class AppointmentTypeInlineRequest {
  /** id */
  id = undefined;
}

class AppointmentTypeInlineSearch {
  /** id */
  id = undefined;

  /** name */
  name = '';
}

class AppointmentTypePractitioner {
  /** appointmentType */
  appointmentType = '';

  /** duration */
  duration = undefined;

  /** id */
  id = undefined;

  /** isEnabled */
  isEnabled = false;

  /** practitioner */
  practitioner = '';

  /** sessions */
  sessions = [];
}

class AppointmentTypePractitionerRequest {
  /** duration */
  duration = undefined;

  /** isEnabled */
  isEnabled = false;

  /** sessions */
  sessions = [];
}

class AppointmentTypeProfile {
  /** bookingAlertMsg */
  bookingAlertMsg = '';

  /** category */
  category = [];

  /** Unit: minutes */
  defaultDuration = undefined;

  /** displayName */
  displayName = '';

  /** isEnableBookingAlert */
  isEnableBookingAlert = false;

  /** isEnableTermsConditions */
  isEnableTermsConditions = false;

  /** isEnabled */
  isEnabled = false;

  /** isEnabledExistingPatients */
  isEnabledExistingPatients = false;

  /** isEnabledNewPatients */
  isEnabledNewPatients = false;

  /** isEnabledTelehealth */
  isEnabledTelehealth = false;

  /** isEnabledVideoTelehealth */
  isEnabledVideoTelehealth = false;

  /** isPaymentRequired */
  isPaymentRequired = false;

  /** isRequiredReferral */
  isRequiredReferral = false;

  /** isRequiredReferralUploaded */
  isRequiredReferralUploaded = false;

  /** name */
  name = '';

  /** pk */
  pk = undefined;

  /** scope */
  scope = '';

  /** termsConditions */
  termsConditions = '';
}

class AppointmentTypeProfileRequest {
  /** bookingAlertMsg */
  bookingAlertMsg = '';

  /** category */
  category = [];

  /** Unit: minutes */
  defaultDuration = undefined;

  /** displayName */
  displayName = '';

  /** isEnableBookingAlert */
  isEnableBookingAlert = false;

  /** isEnableTermsConditions */
  isEnableTermsConditions = false;

  /** isEnabled */
  isEnabled = false;

  /** isEnabledExistingPatients */
  isEnabledExistingPatients = false;

  /** isEnabledNewPatients */
  isEnabledNewPatients = false;

  /** isEnabledTelehealth */
  isEnabledTelehealth = false;

  /** isEnabledVideoTelehealth */
  isEnabledVideoTelehealth = false;

  /** isPaymentRequired */
  isPaymentRequired = false;

  /** isRequiredReferral */
  isRequiredReferral = false;

  /** isRequiredReferralUploaded */
  isRequiredReferralUploaded = false;

  /** termsConditions */
  termsConditions = '';
}

class BlankEnum {}

class BookingsPieChartReport {
  /** amount */
  amount = undefined;

  /** type */
  type = '';
}

class BusinessDataAppointmentTypeInline {
  /** id */
  id = undefined;

  /** name */
  name = '';
}

class BusinessDataAppointmentTypeInlineRequest {
  /** id */
  id = undefined;
}

class BusinessDataPractitionerInline {
  /** avatar */
  avatar = '';

  /** name */
  name = '';
}

class CancellationNoticeUnitEnum {}

class CancellationPolicy {
  /** appointmentType */
  appointmentType = [];

  /** cancellationNotice */
  cancellationNotice = undefined;

  /** cancellationNoticeUnit */
  cancellationNoticeUnit = undefined;

  /** charge */
  charge = '';

  /** isEnabled */
  isEnabled = false;

  /** name */
  name = '';

  /** practitioner */
  practitioner = [];

  /** slug */
  slug = '';
}

class CancellationPolicyRequest {
  /** appointmentType */
  appointmentType = [];

  /** cancellationNotice */
  cancellationNotice = undefined;

  /** cancellationNoticeUnit */
  cancellationNoticeUnit = undefined;

  /** charge */
  charge = '';

  /** isEnabled */
  isEnabled = false;

  /** name */
  name = '';

  /** practitioner */
  practitioner = [];
}

class ContactUsFormModel {
  /** email */
  email = '';

  /** enquiry */
  enquiry = '';

  /** firstName */
  firstName = '';

  /** mobile */
  mobile = '';

  /** surname */
  surname = '';
}

class ContactUsFormModelRequest {
  /** email */
  email = '';

  /** enquiry */
  enquiry = '';

  /** firstName */
  firstName = '';

  /** mobile */
  mobile = '';

  /** surname */
  surname = '';
}

class DailyAppointments {
  /** amount */
  amount = undefined;

  /** date */
  date = '';
}

class DayEnum {}

class DigitalFormTemplate {
  /** appointmentType */
  appointmentType = [];

  /** description */
  description = '';

  /** formUrl */
  formUrl = '';

  /** isEnabled */
  isEnabled = false;

  /** name */
  name = '';

  /** questions */
  questions = undefined;

  /** slug */
  slug = '';

  /** title */
  title = '';
}

class DigitalFormTemplateRequest {
  /** appointmentType */
  appointmentType = [];

  /** description */
  description = '';

  /** isEnabled */
  isEnabled = false;

  /** name */
  name = '';

  /** questions */
  questions = undefined;

  /** title */
  title = '';
}

class GetPracticeDigitalForm {
  /** description */
  description = '';

  /** name */
  name = '';

  /** questions */
  questions = undefined;

  /** title */
  title = '';
}

class LocalityModel {
  /** displayName */
  displayName = '';

  /** id */
  id = undefined;

  /** latitude */
  latitude = '';

  /** longitude */
  longitude = '';

  /** postcode */
  postcode = '';

  /** state */
  state = new StateEnum();

  /** suburb */
  suburb = '';
}

class MyCareTeamModel {
  /** practices */
  practices = [];

  /** practitioners */
  practitioners = [];
}

class MyCareTeamModelRequest {
  /** practices */
  practices = [];

  /** practitioners */
  practitioners = [];
}

class NullEnum {}

class PageBlockModel {
  /** androidLink */
  androidLink = '';

  /** bgImage */
  bgImage = '';

  /** iosLink */
  iosLink = '';

  /** slogan */
  slogan = '';
}

class PageMetaModel {
  /** meta content */
  content = '';

  /** header meta name */
  name = '';
}

class PaginatedAppointmentTypeCategoryModelList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PaginatedAppointmentTypeCategoryNestedModelList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PaginatedAppointmentTypeInlineSearchList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PaginatedAppointmentTypePractitionerList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PaginatedAppointmentTypeProfileList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PaginatedCancellationPolicyList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PaginatedDigitalFormTemplateList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PaginatedPatientBlockList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PaginatedPatientBlockMessageTemplateList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PaginatedPatientFamilyMemberModelList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PaginatedPatientHistoryAppointmentModelList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PaginatedPatientInlineSearchList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PaginatedPaymentRequestModelList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PaginatedPracticeAccreditationModelList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PaginatedPracticeAppointmentList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PaginatedPracticeAreaOfInterestModelList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PaginatedPracticeEducationAndExperienceModelList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PaginatedPracticeFacilityModelList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PaginatedPracticeProfessionDetailsModelList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PaginatedPracticeProfessionModelList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PaginatedPracticeQualificationModelList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PaginatedPracticeServiceTypeModelList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PaginatedPracticeSpokenLanguageModelList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PaginatedPractitionerAppointmentTypeList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PaginatedPractitionerDataModelList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PaginatedPractitionerInlineSearchList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PaginatedPractitionerList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PaginatedReminderAppointmentList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PaginatedReminderSettingsPractitionerModelList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PaginatedReminderTemplateAnchorModelList {
  /** count */
  count = undefined;

  /** next */
  next = '';

  /** previous */
  previous = '';

  /** results */
  results = [];
}

class PatchedPatientFamilyMemberModelRequest {
  /** dob */
  dob = '';

  /** firstName */
  firstName = '';

  /** gender */
  gender = undefined;

  /** surname */
  surname = '';
}

class PatientBlock {
  /** comment */
  comment = '';

  /** displayMessage */
  displayMessage = new PatientBlockMessageTemplateInline();

  /** id */
  id = undefined;

  /** patient */
  patient = new PatientBlockPatientInline();
}

class PatientBlockMessageTemplate {
  /** id */
  id = undefined;

  /** message */
  message = '';

  /** name */
  name = '';
}

class PatientBlockMessageTemplateInline {
  /** id */
  id = undefined;

  /** name */
  name = '';
}

class PatientBlockMessageTemplateInlineRequest {
  /** id */
  id = undefined;
}

class PatientBlockMessageTemplateRequest {
  /** message */
  message = '';

  /** name */
  name = '';
}

class PatientBlockPatientInline {
  /** dob */
  dob = '';

  /** firstName */
  firstName = '';

  /** surname */
  surname = '';
}

class PatientBlockPatientInlineRequest {
  /** dob */
  dob = '';

  /** firstName */
  firstName = '';

  /** surname */
  surname = '';
}

class PatientBlockRequest {
  /** comment */
  comment = '';

  /** displayMessage */
  displayMessage = new PatientBlockMessageTemplateInlineRequest();

  /** patient */
  patient = new PatientBlockPatientInlineRequest();
}

class PatientFamilyMemberModel {
  /** dob */
  dob = '';

  /** firstName */
  firstName = '';

  /** fullName */
  fullName = '';

  /** gender */
  gender = undefined;

  /** id */
  id = undefined;

  /** isSelf */
  isSelf = false;

  /** surname */
  surname = '';
}

class PatientFamilyMemberModelGenderEnum {}

class PatientFamilyMemberModelRequest {
  /** dob */
  dob = '';

  /** firstName */
  firstName = '';

  /** gender */
  gender = undefined;

  /** surname */
  surname = '';
}

class PatientHistoryAppointmentModel {
  /** address */
  address = '';

  /** appointmentDate */
  appointmentDate = '';

  /** appointmentTime */
  appointmentTime = '';

  /** appointmentType */
  appointmentType = '';

  /** avatar */
  avatar = '';

  /** id */
  id = undefined;

  /** isReviewed */
  isReviewed = false;

  /** patient */
  patient = '';

  /** practice */
  practice = '';

  /** practicePhone */
  practicePhone = '';

  /** practiceSlug */
  practiceSlug = '';

  /** practitioner */
  practitioner = '';

  /** status */
  status = '';
}

class PatientInline {
  /** dob */
  dob = '';

  /** firstName */
  firstName = '';

  /** surname */
  surname = '';
}

class PatientInlineSearch {
  /** dob */
  dob = '';

  /** fullName */
  fullName = '';

  /** id */
  id = undefined;
}

class PatientPaymentCardModel {
  /** alias */
  alias = '';

  /** authorized */
  authorized = false;

  /** cardCategory */
  cardCategory = '';

  /** cardCountry */
  cardCountry = '';

  /** cardExpiry */
  cardExpiry = '';

  /** cardHolder */
  cardHolder = '';

  /** cardIssuer */
  cardIssuer = '';

  /** cardNumber */
  cardNumber = '';

  /** cardSubcategory */
  cardSubcategory = '';

  /** cardType */
  cardType = '';

  /** id */
  id = undefined;

  /** token */
  token = '';

  /** transactionCount */
  transactionCount = undefined;
}

class PatientPaymentCardModelRequest {
  /** alias */
  alias = '';

  /** authorized */
  authorized = false;

  /** cardCategory */
  cardCategory = '';

  /** cardCountry */
  cardCountry = '';

  /** cardExpiry */
  cardExpiry = '';

  /** cardHolder */
  cardHolder = '';

  /** cardIssuer */
  cardIssuer = '';

  /** cardNumber */
  cardNumber = '';

  /** cardSubcategory */
  cardSubcategory = '';

  /** cardType */
  cardType = '';

  /** token */
  token = '';

  /** transactionCount */
  transactionCount = undefined;
}

class PatientPieChartReport {
  /** amount */
  amount = undefined;

  /** type */
  type = '';
}

class PatientUpcomingAppointmentModel {
  /** address */
  address = '';

  /** appointmentDate */
  appointmentDate = '';

  /** appointmentTime */
  appointmentTime = '';

  /** appointmentType */
  appointmentType = '';

  /** avatar */
  avatar = '';

  /** checkInStatus */
  checkInStatus = '';

  /** id */
  id = undefined;

  /** patient */
  patient = '';

  /** practice */
  practice = '';

  /** practicePhone */
  practicePhone = '';

  /** practiceSlug */
  practiceSlug = '';

  /** practitioner */
  practitioner = '';

  /** status */
  status = undefined;
}

class PatientUpcomingAppointmentModelRequest {
  /** appointmentDate */
  appointmentDate = '';

  /** checkInStatus */
  checkInStatus = '';

  /** status */
  status = undefined;
}

class PatientUpcomingAppointmentModelStatusEnum {}

class PatientUserProfileModel {
  /** avatar */
  avatar = '';

  /** dob */
  dob = '';

  /** email */
  email = '';

  /** firstName */
  firstName = '';

  /** fullName */
  fullName = '';

  /** gender */
  gender = '';

  /** isEmailValidate */
  isEmailValidate = false;

  /** isMobileValidate */
  isMobileValidate = false;

  /** mobile */
  mobile = '';

  /** surname */
  surname = '';
}

class PatientUserProfileModelRequest {
  /** avatar */
  avatar = '';

  /** dob */
  dob = '';

  /** firstName */
  firstName = '';

  /** gender */
  gender = '';

  /** mobile */
  mobile = '';

  /** surname */
  surname = '';
}

class PatientUserRegisterModel {
  /** avatar */
  avatar = '';

  /** dob */
  dob = '';

  /** email */
  email = '';

  /** firstName */
  firstName = '';

  /** gender */
  gender = '';

  /** mobile */
  mobile = '';

  /** surname */
  surname = '';
}

class PatientUserRegisterModelRequest {
  /** avatar */
  avatar = '';

  /** confirmPassword */
  confirmPassword = '';

  /** dob */
  dob = '';

  /** email */
  email = '';

  /** firstName */
  firstName = '';

  /** gender */
  gender = '';

  /** mobile */
  mobile = '';

  /** password */
  password = '';

  /** practiceId */
  practiceId = '';

  /** surname */
  surname = '';
}

class PaymentRequestModel {
  /** amount */
  amount = '';

  /** appointmentDate */
  appointmentDate = '';

  /** appointmentTime */
  appointmentTime = '';

  /** appointmentType */
  appointmentType = '';

  /** invoice */
  invoice = undefined;

  /** patient */
  patient = new PatientInline();

  /** pk */
  pk = undefined;

  /** practitioner */
  practitioner = new PractitionerInline();

  /** status */
  status = undefined;
}

class PaymentRequestModelStatusEnum {}

class PostPracticeDigitalFormRequest {
  /** filledForm */
  filledForm = undefined;
}

class Practice {
  /** profile */
  profile = new PracticeProfileModel();

  /** slug */
  slug = '';
}

class PracticeAccreditationInline {
  /** name */
  name = '';
}

class PracticeAccreditationInlineRequest {
  /** name */
  name = '';
}

class PracticeAccreditationModel {
  /** name */
  name = '';
}

class PracticeAppointment {
  /** appointmentDate */
  appointmentDate = '';

  /** appointmentStatus */
  appointmentStatus = '';

  /** appointmentTime */
  appointmentTime = '';

  /** appointmentType */
  appointmentType = '';

  /** bookedAt */
  bookedAt = '';

  /** bookedBy */
  bookedBy = '';

  /** paymentStatus */
  paymentStatus = '';

  /** pk */
  pk = undefined;

  /** practitioner */
  practitioner = new BusinessDataPractitionerInline();
}

class PracticeAreaOfInterestModel {
  /** id */
  id = undefined;

  /** name */
  name = '';
}

class PracticeAreaOfInterestModelRequest {
  /** name */
  name = '';
}

class PracticeAuthToken {
  /** expiry */
  expiry = '';

  /** token */
  token = '';

  /** user */
  user = new PracticeLogin();
}

class PracticeCalendarInline {
  /** openHours */
  openHours = [];

  /** specialOpenHours */
  specialOpenHours = [];
}

class PracticeCalendarInlineRequest {
  /** openHours */
  openHours = [];

  /** specialOpenHours */
  specialOpenHours = [];
}

class PracticeDataModel {
  /** address */
  address = '';

  /** directionParkingInfo */
  directionParkingInfo = '';

  /** displayName */
  displayName = '';

  /** fax */
  fax = '';

  /** id */
  id = undefined;

  /** image */
  image = '';

  /** introduction */
  introduction = '';

  /** phone */
  phone = '';

  /** slug */
  slug = '';

  /** e.g.: https://www.example.com */
  website = '';
}

class PracticeEducationAndExperienceModel {
  /** id */
  id = undefined;

  /** name */
  name = '';
}

class PracticeEducationAndExperienceModelRequest {
  /** name */
  name = '';
}

class PracticeFacilityInline {
  /** name */
  name = '';
}

class PracticeFacilityInlineRequest {
  /** name */
  name = '';
}

class PracticeFacilityModel {
  /** name */
  name = '';
}

class PracticeLogin {
  /** avatar */
  avatar = '';

  /** email */
  email = '';

  /** firstName */
  firstName = '';

  /** gender */
  gender = '';

  /** mobile */
  mobile = '';

  /** pk */
  pk = undefined;

  /** practice */
  practice = [];

  /** surname */
  surname = '';
}

class PracticeLoginRequestRequest {
  /** email */
  email = '';

  /** password */
  password = '';
}

class PracticeOpenHourInline {
  /** closeAt */
  closeAt = '';

  /** day */
  day = undefined;

  /** id */
  id = undefined;

  /** openAt */
  openAt = '';
}

class PracticeOpenHourInlineRequest {
  /** closeAt */
  closeAt = '';

  /** day */
  day = undefined;

  /** openAt */
  openAt = '';
}

class PracticePaymentSetup {
  /** clientId */
  clientId = '';

  /** clientSecret */
  clientSecret = '';

  /** id */
  id = undefined;

  /** isEnabled */
  isEnabled = false;

  /** merchantCode */
  merchantCode = '';
}

class PracticePaymentSetupRequest {
  /** clientId */
  clientId = '';

  /** clientSecret */
  clientSecret = '';

  /** isEnabled */
  isEnabled = false;

  /** merchantCode */
  merchantCode = '';
}

class PracticeProfessionDetailsModel {
  /** id */
  id = undefined;

  /** name */
  name = '';
}

class PracticeProfessionModel {
  /** id */
  id = undefined;

  /** name */
  name = '';
}

class PracticeProfileModel {
  /** abn */
  abn = '';

  /** accreditation */
  accreditation = [];

  /** address */
  address = new Address();

  /** calendar */
  calendar = new PracticeCalendarInline();

  /** directionParkingInfo */
  directionParkingInfo = '';

  /** displayName */
  displayName = '';

  /** email */
  email = '';

  /** facility */
  facility = [];

  /** fax */
  fax = '';

  /** image */
  image = '';

  /** introduction */
  introduction = '';

  /** phone */
  phone = '';

  /** socialMedias */
  socialMedias = [];

  /** e.g.: https://www.example.com */
  website = '';
}

class PracticeProfileModelRequest {
  /** abn */
  abn = '';

  /** accreditation */
  accreditation = [];

  /** address */
  address = new AddressRequest();

  /** calendar */
  calendar = new PracticeCalendarInlineRequest();

  /** directionParkingInfo */
  directionParkingInfo = '';

  /** displayName */
  displayName = '';

  /** email */
  email = '';

  /** facility */
  facility = [];

  /** fax */
  fax = '';

  /** image */
  image = '';

  /** introduction */
  introduction = '';

  /** phone */
  phone = '';

  /** socialMedias */
  socialMedias = [];

  /** e.g.: https://www.example.com */
  website = '';
}

class PracticeQualificationModel {
  /** id */
  id = undefined;

  /** name */
  name = '';
}

class PracticeQualificationModelRequest {
  /** name */
  name = '';
}

class PracticeQualificationsModel {
  /** name */
  name = '';
}

class PracticeReminderHint {
  /** day */
  day = undefined;

  /** hasReminder */
  hasReminder = false;
}

class PracticeReminderModel {
  /** title */
  title = '';
}

class PracticeRequest {
  /** profile */
  profile = new PracticeProfileModelRequest();
}

class PracticeRequestBody {
  /** name */
  name = '';

  /** slug */
  slug = '';
}

class PracticeServiceType {
  /** name */
  name = '';
}

class PracticeServiceTypeModel {
  /** id */
  id = undefined;

  /** name */
  name = '';
}

class PracticeServiceTypeModelRequest {
  /** name */
  name = '';
}

class PracticeSiteModel {
  /** clientId */
  clientId = '';

  /** name */
  name = '';

  /** serverHost */
  serverHost = '';

  /** serverPort */
  serverPort = undefined;

  /** siteId */
  siteId = undefined;

  /** slug */
  slug = '';

  /** websocketUrl */
  websocketUrl = '';
}

class PracticeSiteModelRequest {
  /** name */
  name = '';

  /** serverHost */
  serverHost = '';

  /** serverPort */
  serverPort = undefined;

  /** siteId */
  siteId = undefined;
}

class PracticeSocialMediaModel {
  /** link */
  link = '';

  /** socialMedia */
  socialMedia = undefined;
}

class PracticeSocialMediaModelRequest {
  /** link */
  link = '';

  /** socialMedia */
  socialMedia = undefined;
}

class PracticeSpecialOpenHourInline {
  /** closeAt */
  closeAt = '';

  /** date */
  date = '';

  /** id */
  id = undefined;

  /** isClosed */
  isClosed = false;

  /** notes */
  notes = '';

  /** openAt */
  openAt = '';
}

class PracticeSpecialOpenHourInlineRequest {
  /** closeAt */
  closeAt = '';

  /** date */
  date = '';

  /** isClosed */
  isClosed = false;

  /** notes */
  notes = '';

  /** openAt */
  openAt = '';
}

class PracticeSpokenLanguageModel {
  /** id */
  id = undefined;

  /** name */
  name = '';
}

class PracticeSpokenLanguageModelRequest {
  /** name */
  name = '';
}

class Practitioner {
  /** fullName */
  fullName = '';

  /** profile */
  profile = new PractitionerProfile();

  /** slug */
  slug = '';
}

class PractitionerAppointment {
  /** amount */
  amount = undefined;

  /** practitioner */
  practitioner = '';
}

class PractitionerAppointmentType {
  /** appointmentType */
  appointmentType = '';

  /** duration */
  duration = undefined;

  /** id */
  id = undefined;

  /** isEnabled */
  isEnabled = false;

  /** practitioner */
  practitioner = '';

  /** sessions */
  sessions = [];
}

class PractitionerAppointmentTypeRequest {
  /** duration */
  duration = undefined;

  /** isEnabled */
  isEnabled = false;

  /** sessions */
  sessions = [];
}

class PractitionerAppointmentTypeSession {
  /** day */
  day = undefined;

  /** endDate */
  endDate = '';

  /** endTime */
  endTime = '';

  /** id */
  id = undefined;

  /** repeat */
  repeat = new RepeatEnum();

  /** startTime */
  startTime = '';
}

class PractitionerAppointmentTypeSessionRequest {
  /** day */
  day = undefined;

  /** endDate */
  endDate = '';

  /** endTime */
  endTime = '';

  /** repeat */
  repeat = new RepeatEnum();

  /** startTime */
  startTime = '';
}

class PractitionerDataModel {
  /** about */
  about = '';

  /** address */
  address = '';

  /** availableAppointment */
  availableAppointment = '';

  /** avatar */
  avatar = '';

  /** displayName */
  displayName = '';

  /** distance */
  distance = '';

  /** educations */
  educations = [];

  /** gender */
  gender = '';

  /** id */
  id = undefined;

  /** nextAvailableAppointment */
  nextAvailableAppointment = '';

  /** phone */
  phone = '';

  /** practice */
  practice = '';

  /** qualifications */
  qualifications = [];

  /** serviceTypes */
  serviceTypes = [];

  /** slug */
  slug = '';

  /** specialInterests */
  specialInterests = [];

  /** spokenLanguages */
  spokenLanguages = [];
}

class PractitionerInline {
  /** fullName */
  fullName = '';

  /** id */
  id = undefined;
}

class PractitionerInlineRequest {
  /** id */
  id = undefined;
}

class PractitionerInlineSearch {
  /** fullName */
  fullName = '';

  /** id */
  id = undefined;
}

class PractitionerProfile {
  /** about */
  about = '';

  /** avatar */
  avatar = '';

  /** displayName */
  displayName = '';

  /** educations */
  educations = [];

  /** fullName */
  fullName = '';

  /** gender */
  gender = undefined;

  /** isEnabledExistingPatients */
  isEnabledExistingPatients = false;

  /** isEnabledNewPatients */
  isEnabledNewPatients = false;

  /** isEnabledOnlineBooking */
  isEnabledOnlineBooking = false;

  /** isRequirePatientContact */
  isRequirePatientContact = false;

  /** isRequirePayment */
  isRequirePayment = false;

  /** profession */
  profession = undefined;

  /** professionDetail */
  professionDetail = undefined;

  /** qualifications */
  qualifications = [];

  /** serviceTypes */
  serviceTypes = [];

  /** specialInterests */
  specialInterests = [];

  /** spokenLanguages */
  spokenLanguages = [];
}

class PractitionerProfileGenderEnum {}

class PractitionerProfileRequest {
  /** about */
  about = '';

  /** avatar */
  avatar = '';

  /** displayName */
  displayName = '';

  /** educations */
  educations = [];

  /** gender */
  gender = undefined;

  /** isEnabledExistingPatients */
  isEnabledExistingPatients = false;

  /** isEnabledNewPatients */
  isEnabledNewPatients = false;

  /** isEnabledOnlineBooking */
  isEnabledOnlineBooking = false;

  /** isRequirePatientContact */
  isRequirePatientContact = false;

  /** isRequirePayment */
  isRequirePayment = false;

  /** profession */
  profession = undefined;

  /** professionDetail */
  professionDetail = undefined;

  /** qualifications */
  qualifications = [];

  /** serviceTypes */
  serviceTypes = [];

  /** specialInterests */
  specialInterests = [];

  /** spokenLanguages */
  spokenLanguages = [];
}

class PractitionerRequest {
  /** profile */
  profile = new PractitionerProfileRequest();
}

class ReminderAppointment {
  /** appointmentTime */
  appointmentTime = '';

  /** appointmentType */
  appointmentType = '';

  /** patient */
  patient = '';

  /** pk */
  pk = undefined;

  /** practitioner */
  practitioner = '';

  /** reminders */
  reminders = [];
}

class ReminderSettingsModel {
  /** hours prior */
  appPriorHour = undefined;

  /** Hours prior */
  emailPriorHour = undefined;

  /** isAppEnable */
  isAppEnable = false;

  /** isEmailEnabled */
  isEmailEnabled = false;

  /** isEnabled */
  isEnabled = false;

  /** isPatientConfirmationRequired */
  isPatientConfirmationRequired = false;

  /** isSmsEnabled */
  isSmsEnabled = false;

  /** Hours prior */
  smsPriorHour = undefined;

  /** template */
  template = '';
}

class ReminderSettingsModelRequest {
  /** hours prior */
  appPriorHour = undefined;

  /** Hours prior */
  emailPriorHour = undefined;

  /** isAppEnable */
  isAppEnable = false;

  /** isEmailEnabled */
  isEmailEnabled = false;

  /** isEnabled */
  isEnabled = false;

  /** isPatientConfirmationRequired */
  isPatientConfirmationRequired = false;

  /** isSmsEnabled */
  isSmsEnabled = false;

  /** Hours prior */
  smsPriorHour = undefined;

  /** template */
  template = '';
}

class ReminderSettingsPractitionerModel {
  /** isEnabled */
  isEnabled = false;

  /** pk */
  pk = undefined;

  /** practitioner */
  practitioner = '';
}

class ReminderSettingsPractitionerModelRequest {
  /** isEnabled */
  isEnabled = false;
}

class ReminderTemplateAnchorModel {
  /** name */
  name = '';

  /** slug */
  slug = '';
}

class RemindersModel {
  /** deliveryMethod */
  deliveryMethod = '';

  /** pk */
  pk = undefined;

  /** sentTime */
  sentTime = '';

  /** status */
  status = '';
}

class RepeatEnum {}

class SessionModel {
  /** date */
  date = '';

  /** description */
  description = '';

  /** endDate */
  endDate = '';

  /** endTo */
  endTo = '';

  /** pk */
  pk = undefined;

  /** practitioner */
  practitioner = undefined;

  /** repeat */
  repeat = new RepeatEnum();

  /** startFrom */
  startFrom = '';
}

class SessionModelRequest {
  /** date */
  date = '';

  /** description */
  description = '';

  /** endDate */
  endDate = '';

  /** endTo */
  endTo = '';

  /** practitioner */
  practitioner = undefined;

  /** repeat */
  repeat = new RepeatEnum();

  /** startFrom */
  startFrom = '';
}

class SocialMediaEnum {}

class SpecialtyModel {
  /** id */
  id = undefined;

  /** name */
  name = '';
}

class SpokenLanguage {
  /** id */
  id = undefined;

  /** name */
  name = '';
}

class StateEnum {}

class WebPageModel {
  /** blocks */
  blocks = [];

  /** metas */
  metas = [];

  /** Webpage title */
  title = '';
}

export const doctors = {
  Address,
  AddressRequest,
  AppointmentTypeCategoryModel,
  AppointmentTypeCategoryModelRequest,
  AppointmentTypeCategoryNestedModel,
  AppointmentTypeCategoryNestedModelRequest,
  AppointmentTypeInline,
  AppointmentTypeInlineCategory,
  AppointmentTypeInlineCategoryRequest,
  AppointmentTypeInlineRequest,
  AppointmentTypeInlineSearch,
  AppointmentTypePractitioner,
  AppointmentTypePractitionerRequest,
  AppointmentTypeProfile,
  AppointmentTypeProfileRequest,
  BlankEnum,
  BookingsPieChartReport,
  BusinessDataAppointmentTypeInline,
  BusinessDataAppointmentTypeInlineRequest,
  BusinessDataPractitionerInline,
  CancellationNoticeUnitEnum,
  CancellationPolicy,
  CancellationPolicyRequest,
  ContactUsFormModel,
  ContactUsFormModelRequest,
  DailyAppointments,
  DayEnum,
  DigitalFormTemplate,
  DigitalFormTemplateRequest,
  GetPracticeDigitalForm,
  LocalityModel,
  MyCareTeamModel,
  MyCareTeamModelRequest,
  NullEnum,
  PageBlockModel,
  PageMetaModel,
  PaginatedAppointmentTypeCategoryModelList,
  PaginatedAppointmentTypeCategoryNestedModelList,
  PaginatedAppointmentTypeInlineSearchList,
  PaginatedAppointmentTypePractitionerList,
  PaginatedAppointmentTypeProfileList,
  PaginatedCancellationPolicyList,
  PaginatedDigitalFormTemplateList,
  PaginatedPatientBlockList,
  PaginatedPatientBlockMessageTemplateList,
  PaginatedPatientFamilyMemberModelList,
  PaginatedPatientHistoryAppointmentModelList,
  PaginatedPatientInlineSearchList,
  PaginatedPaymentRequestModelList,
  PaginatedPracticeAccreditationModelList,
  PaginatedPracticeAppointmentList,
  PaginatedPracticeAreaOfInterestModelList,
  PaginatedPracticeEducationAndExperienceModelList,
  PaginatedPracticeFacilityModelList,
  PaginatedPracticeProfessionDetailsModelList,
  PaginatedPracticeProfessionModelList,
  PaginatedPracticeQualificationModelList,
  PaginatedPracticeServiceTypeModelList,
  PaginatedPracticeSpokenLanguageModelList,
  PaginatedPractitionerAppointmentTypeList,
  PaginatedPractitionerDataModelList,
  PaginatedPractitionerInlineSearchList,
  PaginatedPractitionerList,
  PaginatedReminderAppointmentList,
  PaginatedReminderSettingsPractitionerModelList,
  PaginatedReminderTemplateAnchorModelList,
  PatchedPatientFamilyMemberModelRequest,
  PatientBlock,
  PatientBlockMessageTemplate,
  PatientBlockMessageTemplateInline,
  PatientBlockMessageTemplateInlineRequest,
  PatientBlockMessageTemplateRequest,
  PatientBlockPatientInline,
  PatientBlockPatientInlineRequest,
  PatientBlockRequest,
  PatientFamilyMemberModel,
  PatientFamilyMemberModelGenderEnum,
  PatientFamilyMemberModelRequest,
  PatientHistoryAppointmentModel,
  PatientInline,
  PatientInlineSearch,
  PatientPaymentCardModel,
  PatientPaymentCardModelRequest,
  PatientPieChartReport,
  PatientUpcomingAppointmentModel,
  PatientUpcomingAppointmentModelRequest,
  PatientUpcomingAppointmentModelStatusEnum,
  PatientUserProfileModel,
  PatientUserProfileModelRequest,
  PatientUserRegisterModel,
  PatientUserRegisterModelRequest,
  PaymentRequestModel,
  PaymentRequestModelStatusEnum,
  PostPracticeDigitalFormRequest,
  Practice,
  PracticeAccreditationInline,
  PracticeAccreditationInlineRequest,
  PracticeAccreditationModel,
  PracticeAppointment,
  PracticeAreaOfInterestModel,
  PracticeAreaOfInterestModelRequest,
  PracticeAuthToken,
  PracticeCalendarInline,
  PracticeCalendarInlineRequest,
  PracticeDataModel,
  PracticeEducationAndExperienceModel,
  PracticeEducationAndExperienceModelRequest,
  PracticeFacilityInline,
  PracticeFacilityInlineRequest,
  PracticeFacilityModel,
  PracticeLogin,
  PracticeLoginRequestRequest,
  PracticeOpenHourInline,
  PracticeOpenHourInlineRequest,
  PracticePaymentSetup,
  PracticePaymentSetupRequest,
  PracticeProfessionDetailsModel,
  PracticeProfessionModel,
  PracticeProfileModel,
  PracticeProfileModelRequest,
  PracticeQualificationModel,
  PracticeQualificationModelRequest,
  PracticeQualificationsModel,
  PracticeReminderHint,
  PracticeReminderModel,
  PracticeRequest,
  PracticeRequestBody,
  PracticeServiceType,
  PracticeServiceTypeModel,
  PracticeServiceTypeModelRequest,
  PracticeSiteModel,
  PracticeSiteModelRequest,
  PracticeSocialMediaModel,
  PracticeSocialMediaModelRequest,
  PracticeSpecialOpenHourInline,
  PracticeSpecialOpenHourInlineRequest,
  PracticeSpokenLanguageModel,
  PracticeSpokenLanguageModelRequest,
  Practitioner,
  PractitionerAppointment,
  PractitionerAppointmentType,
  PractitionerAppointmentTypeRequest,
  PractitionerAppointmentTypeSession,
  PractitionerAppointmentTypeSessionRequest,
  PractitionerDataModel,
  PractitionerInline,
  PractitionerInlineRequest,
  PractitionerInlineSearch,
  PractitionerProfile,
  PractitionerProfileGenderEnum,
  PractitionerProfileRequest,
  PractitionerRequest,
  ReminderAppointment,
  ReminderSettingsModel,
  ReminderSettingsModelRequest,
  ReminderSettingsPractitionerModel,
  ReminderSettingsPractitionerModelRequest,
  ReminderTemplateAnchorModel,
  RemindersModel,
  RepeatEnum,
  SessionModel,
  SessionModelRequest,
  SocialMediaEnum,
  SpecialtyModel,
  SpokenLanguage,
  StateEnum,
  WebPageModel
};
