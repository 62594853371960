import React, { useCallback, useRef, useState } from 'react';
import _ from 'lodash';
import Captcha from 'react-captcha-code';
import { Breadcrumb, Button, Form, Input, message, Result, Space } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { apiPatient } from 'src/services/doctors/mods/patient';
import { Link } from 'react-router-dom';
import './Forgot.scss';

const Forgot: React.FC = () => {
  const [form] = Form.useForm();



  const [isSuccerr, setIsSuccerr] = useState(false);

  const [captcha, setCaptcha] = useState('');

  const handleChange = useCallback(code => {
    setCaptcha(code);
  }, []);



  const [postAuthForgetPasswordTrigger, { isLoading }] = apiPatient.usePostAuthForgetPasswordMutation();
  const onFinish = value => {
    const bodyParams = { ...value };
    postAuthForgetPasswordTrigger({ bodyParams })
      .unwrap()
      .then(() => {
        setIsSuccerr(true);
      });
  };

  const renderForm = () => {
    return (
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          name="email"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ]}
        >
          <Input placeholder="E-mail" prefix={<MailOutlined />} />
        </Form.Item>

        <Form.Item>
          <Space.Compact block>
            <Form.Item
              noStyle
              name="code"
              rules={[
                {
                  required: true,
                  message: 'Please enter Code!',
                },
                {
                  validateTrigger: 'blur',
                  validator(rule, value, callback) {
                    if (value && value !== captcha) {
                      return callback('Please enter correct verify code');
                    }
                    callback();
                  },
                },
              ]}
            >
              <Input placeholder="Code" />
            </Form.Item>
            <Captcha onChange={handleChange}  />
          </Space.Compact>
        </Form.Item>

        <Form.Item>
          <Button
            loading={isLoading}
            type="primary"
            htmlType="submit"
            size="large"
            style={{ width: '100%', marginTop: 20 }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const renderResult = () => {
    return (
      <Result
        status="success"
        title="Successfully Purchased Cloud Server ECS!"
        subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
      />
    );
  };

  return (
    <div className="page-Forgot">
      <Breadcrumb style={{ marginBottom: 20 }}>
        <Breadcrumb.Item>
          <Link to="/login">Login</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Forgot password</Breadcrumb.Item>
      </Breadcrumb>
      {isSuccerr ? renderResult() : renderForm()}
    </div>
  );
};

export default Forgot;
