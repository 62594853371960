/* eslint-disable no-restricted-globals */

/**
 * @description 设置请求pathname
 */

import _ from 'lodash';
import queryString from 'query-string';

/** 获取 url 字符串指定 name 的参数 */
export const getUrlParam = (name: string, params?: string) => {
  return queryString.parse(params)[name] || '';
};

/**
 * 把 path 中的 `{ appName }` 替换成 `params` 中的值, 把 params append 到 query
 */
export function getUrl(path: string, queryParams: any, method: string) {
  const params = _.cloneDeep(queryParams);
  const url = path.replace(/\{([^\\}]*(?:\\.[^\\}]*)*)\}/gm, (match, key) => {
    key = key.trim();

    if (params[key] !== undefined) {
      const value = params[key];
      delete params[key];
      return value;
    } else {
      console.warn('Please set value for template key: ', key);
      return '';
    }
  });

  if (_.toLower(method) === 'get') {
    return url;
  }

  return (
    url +
    '?' +
    _.map(params, (value, key) => (value === 'undefined' ? '' : `${key}=${value}`))
      .filter(_.identity)
      .join('&')
  );
}

// desc@获取Fetch请求的url前缀 author@lingyi
export function getUrlPrefix(url: string) {
  // 写死的链接不处理
  if (url.indexOf('//') > -1) {
    return '';
  }
  return `${process.env.REACT_APP_API_URL}`;
}

// desc@拼接Fetch请求的url author@lingyi
export function addUrlPrefix(url: string) {
  return getUrlPrefix(url) + url;
}

const is = {
  array: value => Array.isArray(value),
  object: value => value !== null && !is.array(value) && typeof value === 'object',
  undef: value => typeof value === 'undefined',
};

function error(...msgs: string[]) {
  console.error(...msgs);
}

export function assertDataIntegrity(data, init, location: string) {
  // 数组
  if (is.array(init) && is.undef(data)) {
    // tslint:disable-next-line
    error(location, `接口文档定义为数组，但接口返回为${data}，请联系后端初始化返回值`);
    return init;
  } else if (is.array(init) && !is.array(data)) {
    // tslint:disable-next-line
    error(location, `接口文档定义为数组，但接口返回为${data}，请校验`);
    return data;
  }

  // 对象
  if (is.object(init) && is.undef(data)) {
    // tslint:disable-next-line
    error(location, `接口文档定义为对象，但接口返回为${data}，请联系后端初始化返回值`);
    return init;
  } else if (is.object(init) && is.object(data)) {
    // 对象深度检测
    return _.mapValues(init, (value, field) => {
      // tslint:disable-next-line
      return assertDataIntegrity(data[field], value, `${location}的${field}字段`);
    });
  }

  return data;
}

export function makeSwaggerUrl(path: string, pathParams?: any, queryParams?: any) {
  let url = path;

  if (pathParams) {
    Object.keys(pathParams).forEach(key => {
      url = url.replace(new RegExp(_.escapeRegExp(`{${key}}`), 'g'), (pathParams as any)[key]);
    });
  }

  if (queryParams) {
    url += `?${Object.keys(queryParams)
      .filter(key => (queryParams as any)[key] !== undefined) // 过滤掉值为undefined的情况
      .map(key => `${key}=${(queryParams as any)[key]}`)
      .join('&')}`;
  }

  return addUrlPrefix(url);
}
