import React from 'react';
import _ from 'lodash';
import { browserHistory } from 'src/router';
import { apiPatient } from 'src/services/doctors/mods/patient';
import './index.scss';
import { DOC_USINFO, DOC_TOKEN } from 'src/utils/Appoint';

const Account: React.FC = () => {
  const [postAuthLogoutTrigger] = apiPatient.usePostAuthLogoutMutation();

  const handleClick = item => {
    if (item.isOut) {
    
      postAuthLogoutTrigger({}).then(res => {
        localStorage.setItem(DOC_TOKEN, '');
        localStorage.setItem(DOC_USINFO, '');
        browserHistory.replace(item.path);
      });
      return;
    }
    browserHistory.push(item.path);
  };
  const itemData = [
    {
      icon: 'doc-gerenshezhi',
      name: 'Profile',
      path: '/account/profile',
    },
    {
      icon: 'doc-jurassic_users',
      name: 'Family Members',
      path: '/account/family',
    },
    {
      icon: 'doc-xinyongqia',
      name: 'Payments',
      path: '/account/payment',
    },
    {
      icon: 'doc-lishijilu',
      name: 'Historical Appointment',
      path: '/account/historical',
    },
    {
      icon: 'doc-exit',
      name: 'Sign Out',
      path: '/login',
      isOut: true,
    },
  ];

  return (
    <div className="page-account">
      {_.map(itemData, item => (
        <div className="account-item" key={item.name} onClick={() => handleClick(item)}>
          <div className="icon">
            <span className={`iconfont ${item.icon}`}></span>
          </div>
          <div className="value">{item.name}</div>
        </div>
      ))}
    </div>
  );
};

export default Account;
