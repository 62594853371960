import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import qs from 'query-string';
import { message, Space } from 'antd';
import { Link, Outlet, NavLink } from 'react-router-dom';
import './index.scss';
import { getPrimary } from 'src/hook/usePrimary';
import { CloseOutlined } from '@ant-design/icons';
import usePhion from 'src/hook/usePhion';

const Layouts: React.FC = () => {
  const [primary] = getPrimary();

  const [isMobile, bodyWidth] = usePhion();

  const search = window.location.search;
  const { isClear } = qs.parse(search);

  const showClear = React.useMemo(() => {
    if (isMobile || !isClear) {
      return false;
    }
    return true;
  }, [isClear, isMobile]);

  const menu = [
    {
      key: 1,
      icon: 'doc-buzou',
      path: '/book',
      label: 'Book',
    },
    {
      key: 2,
      icon: 'doc-rili',
      path: '/appointment',
      label: 'Appointment',
    },
    {
      key: 3,
      icon: 'doc-user',
      path: '/account',
      label: 'Account',
    },
  ];
  return (
    <div className="page-layouts">
      <div className="page-header" style={{ background: primary }}>
        <div className="header-left">
          <div>Book Appointment</div>
          <div className="header-left-subtitle">Powered by DoctorsHub</div>
        </div>
        <div className="header-right">
          <Space size={bodyWidth < 480 ? 'middle' : 'large'}>
            {_.map(menu, ({ label, path, key, icon }) => (
              <NavLink to={path} className="header-right-item" key={key}>
                <span className={cx('iconfont', icon)}></span>
                <span>{label}</span>
              </NavLink>
            ))}
          </Space>
        </div>
        {showClear && (
          <div
            className="clear"
            onClick={() => {
              window.top?.postMessage('DoctorsHubClear', '*');
            }}
          >
            <CloseOutlined />
          </div>
        )}
      </div>
      <div className="page-content">
        <div style={{ minHeight: 380 }}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layouts;
