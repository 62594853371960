import { Avatar, Breadcrumb, Divider, List, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { apiPatient } from 'src/services/doctors/mods/patient';
import './Historical.scss';

const Historical: React.FC = () => {
  const [dataSource, setdataSource] = useState<Array<defs.doctors.PatientHistoryAppointmentModel>>([]);
  const [page, setpage] = useState(1);

  const [getHistoricalAppointmentTrigger, { data, isFetching: loading }] =
    apiPatient.useLazyGetHistoricalAppointmentQuery();

  const loadMoreData = () => {
    if (loading) {
      return;
    }
    getHistoricalAppointmentTrigger({ queryParams: { page, size: 10 } })
      .unwrap()
      .then(res => {
        setpage(page + 1);
        setdataSource([...dataSource, ...res.results]);
      });
  };

  useEffect(() => {
    loadMoreData();
  }, []);
  return (
    <div className="page-historical">
      <Breadcrumb style={{ marginBottom: 20 }}>
        <Breadcrumb.Item>
          <Link to="/account">Account</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Historical Appointment</Breadcrumb.Item>
      </Breadcrumb>
      <div id="scrollhistorical" className="historical-content">
        <InfiniteScroll
          dataLength={dataSource.length}
          next={loadMoreData}
          hasMore={dataSource.length < data?.count}
          loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
          endMessage={dataSource.length > 0 && <Divider plain>It is all, nothing more 🤐</Divider>}
          scrollableTarget="scrollhistorical"
        >
          <List
            dataSource={dataSource}
            renderItem={item => (
              <div className="item">
                <div className="avatar">
                  <Avatar size={64} src={item.avatar} icon={<UserOutlined />} />
                </div>
                <div className="con-list">
                  <div className="con-title">Dr.Maha Qidwai</div>
                  <div className="con-item">
                    <div className="icon">
                      <span className="iconfont doc-clock"></span>
                    </div>
                    <div className="value">{item.appointmentTime}</div>
                  </div>
                  <div className="con-item">
                    <div className="icon">
                      <span className="iconfont doc-yiyuan"></span>
                    </div>
                    <div className="value">All Health Medical Center</div>
                  </div>
                  <div className="con-item">
                    <div className="icon">
                      <span className="iconfont doc-dizhi"></span>
                    </div>
                    <div className="value">{item.address}</div>
                  </div>
                  <div className="con-item">
                    <div className="icon">
                      <span className="iconfont doc-dianhua"></span>
                    </div>
                    <div className="value">{item.practicePhone}</div>
                  </div>
                  <div className="con-item">
                    <div className="icon">
                      <span className="iconfont doc-yonghu"></span>
                    </div>
                    <div className="value">{item.practice}</div>
                  </div>
                </div>
              </div>
            )}
          />
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default Historical;
