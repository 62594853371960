import { ConfigProvider, message } from 'antd';
import React from 'react';
import { Route } from 'react-router';
import { Routes } from 'react-router-dom';
import NotFound from 'src/layouts/NotFound';

import Layout from '../layouts';
import Login from './Login';
import Book from './Book';
import Account from './Account';
import Appointment from './Appointment';
import { Navigate } from 'src/router';
import { getPrimary } from 'src/hook/usePrimary';
import dayjs from 'dayjs';
import enUS from 'antd/locale/en_US';
import 'dayjs/locale/en';
import Profile from './Account/Profile';
import Family from './Account/Family';
import FamilyEdit from './Account/FamilyEdit';
import Payment from './Account/Payment';
import PaymentEdit from './Account/PaymentEdit';
import Historical from './Account/Historical';
import Register from './Login/Register';
import Forgot from './Login/Forgot';

dayjs.locale('en');
message.config({
  top: 100,
  maxCount: 2,
});

const getRotes = () => {
  const [primary, textColor] = getPrimary();

  return (
    <ConfigProvider
      locale={enUS}
      theme={{
        token: {
          fontSize: 16,
          colorTextPlaceholder: 'rgba(0, 0, 0, 0.55)',
        },
        components: {
          Button: {
            colorPrimary: primary,
            colorTextLightSolid: textColor,
          },
          Input: {
            controlHeight: 50,
          },
          DatePicker: {
            controlHeight: 50,
          },
          Select: {
            controlHeight: 50,
          },
        },
      }}
    >
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="/book" />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="forgot" element={<Forgot />} />
          <Route path="book" element={<Book />} />
          <Route path="account" element={<Account />} />
          <Route path="account/profile" element={<Profile />} />
          <Route path="account/family" element={<Family />} />
          <Route path="account/family/edit" element={<FamilyEdit />} />
          <Route path="account/family/edit/:id" element={<FamilyEdit />} />
          <Route path="account/payment" element={<Payment />} />
          <Route path="account/payment/edit" element={<PaymentEdit />} />
          <Route path="account/historical" element={<Historical />} />
          <Route path="appointment" element={<Appointment />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </ConfigProvider>
  );
};

export { getRotes };
