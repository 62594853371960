import { useRef, useMemo, useLayoutEffect } from 'react';
import {
  useOutlet,
  useLocation as useRouterLocation,
  useInRouterContext,
} from 'react-router-dom';
import qs from 'query-string';
import _ from 'lodash';

interface Defines {
  key: string;
  url: string;
  children?: any;
}

// 路由获取search params
export function useSearchParams(): any {
  const { search } = useRouterLocation();
  const isInRouter = useInRouterContext();
  const params = useMemo(() => qs.parse(search), [search]);

  if (!isInRouter) {
    return null;
  }

  return params;
}

export function useLocation() {
  const location = useRouterLocation();
  return useMemo(() => ({ ...location, query: qs.parse(location.search) }), [location]);
}

/** 用来缓存当前的组件，返回值为： children, params */
export function useMemoRoute(definitions: Defines[]) {
  const outLet = useOutlet();

  const params = useSearchParams();
  const { pathname } = useLocation();
  const defRef = useRef<Defines[]>(definitions);
  const lastIndex = useRef<number>(0);
  const children = _.get(outLet, 'props.children.props.value.outlet.props.children');

  const findIndex = defRef.current?.findIndex(item => item.url === pathname);
  if (findIndex !== -1) {
    lastIndex.current = findIndex;
    defRef.current = defRef.current.map(item => {
      if (item.url === pathname) {
        return {
          ...item,
          children,
        };
      }
      return item;
    });
  }
  const currentChildren = defRef.current?.[lastIndex.current]?.children;
  return [currentChildren, params];
}

export const useRouterTitle = (title: string) => {
  useLayoutEffect(() => {
    if (title) {
      document.title = `Doctors Hub - ${title}`;
    }
  }, [title]);
  return null;
};
