import qs from 'query-string';

const colorRgb = sColor => {
  sColor = sColor.toLowerCase();
  //十六进制颜色值的正则表达式
  var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
  // 如果是16进制颜色
  if (sColor && reg.test(sColor)) {
    if (sColor.length === 4) {
      var sColorNew = '#';
      for (var i = 1; i < 4; i += 1) {
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1));
      }
      sColor = sColorNew;
    }
    //处理六位的颜色值
    var sColorChange = [];
    for (var i = 1; i < 7; i += 2) {
      sColorChange.push(parseInt('0x' + sColor.slice(i, i + 2)));
    }
    return 'rgb(' + sColorChange.join(',') + ')';
  }
  return sColor;
};

const isDrak = color => {
  var arr = color.replace('rgb(', '').replace(')', '').split(',').map(Number);

  var $grayLevel = arr[0] * 0.299 + arr[1] * 0.587 + arr[2] * 0.114;

  if ($grayLevel >= 192) {
    return false;
  } else {
    return true;
  }
};

export function getPrimary(): [string, string] {
  const search = window.location.search;
  const { theme } = qs.parse(search);
  const primary = theme ? `#${theme}` : '#ffeb3b';
  const textColor = isDrak(colorRgb(primary)) ? '#fff' : '#000';
  return [primary, textColor];
}
