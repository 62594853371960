import React, { useEffect, useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Empty, Spin } from 'antd';
import { apiPatient } from 'src/services/doctors/mods/patient';
import _ from 'lodash';
import './index.scss';

const Appointment: React.FC = () => {
  const [getUpcomingAppointmentTrigger, { data, isFetching: loading }] =
    apiPatient.useLazyGetUpcomingAppointmentQuery();

  useEffect(() => {
    getUpcomingAppointmentTrigger({});
  }, []);
  return (
    <div className="appointment">
      <div className="appointment-content">
        <Spin spinning={loading}>
          {data?.length > 0 ? (
            _.map(data, (item: defs.doctors.PatientUpcomingAppointmentModel) => (
              <div className="item" key={item.id}>
                <div className="avatar">
                  <Avatar size={64} src={item.avatar} icon={<UserOutlined />} />
                </div>
                <div className="con-list">
                  <div className="con-title">{item.practitioner}</div>
                  <div className="con-item">
                    <div className="icon">
                      <span className="iconfont doc-clock"></span>
                    </div>
                    <div className="value">{item.appointmentDate} {item.appointmentTime}</div>
                  </div>
                  <div className="con-item">
                    <div className="icon">
                      <span className="iconfont doc-yiyuan"></span>
                    </div>
                    <div className="value">{item.practice}</div>
                  </div>
                  <div className="con-item">
                    <div className="icon">
                      <span className="iconfont doc-dizhi"></span>
                    </div>
                    <div className="value">{item.address}</div>
                  </div>
                 {item.practicePhone&& <div className="con-item">
                    <div className="icon">
                      <span className="iconfont doc-dianhua"></span>
                    </div>
                    <div className="value">{item.practicePhone}</div>
                  </div>}
                  <div className="con-item">
                    <div className="icon">
                      <span className="iconfont doc-yonghu"></span>
                    </div>
                    <div className="value">{item.patient}</div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <Empty />
          )}
        </Spin>
      </div>
    </div>
  );
};

export default Appointment;
