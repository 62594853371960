import { message, notification } from 'antd';
import { FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { browserHistory } from 'src/router';
import { DOC_TOKEN, DOC_USINFO } from './Appoint';

class ObjectToFormdataConfig {
  /**
   * include array indices in FormData keys
   */
  indices = false;

  /**
   * treat null values like undefined values and ignore them
   */
  nullsAsUndefineds = false;

  /**
   * convert true or false to 1 or 0 respectively
   */
  booleansAsIntegers = false;

  /**
   * store arrays even if they're empty
   */
  allowEmptyArrays = false;
}

export class FetchExtraConfig {
  /** 是否默认抛出请求错误信息，默认自动 throw error  */
  defaultThrowError? = true;
}

const isUndefined = (value: any) => value === undefined;

const isNull = (value: any) => value === null;

const isBoolean = (value: any) => typeof value === 'boolean';

const isObject = (value: any) => value === Object(value);

const isArray = (value: any) => Array.isArray(value);

const isDate = (value: any) => value instanceof Date;

const isBlob = (value: any) =>
  value && typeof value.size === 'number' && typeof value.type === 'string' && typeof value.slice === 'function';

const isFile = (value: any) =>
  isBlob(value) &&
  typeof value.name === 'string' &&
  (typeof value.lastModifiedDate === 'object' || typeof value.lastModified === 'number');

const objectToFormData = (
  obj: any,
  rowConfig?: ObjectToFormdataConfig,
  existingFormData?: FormData,
  keyPrefix?: string,
) => {
  const config = rowConfig || new ObjectToFormdataConfig();
  const formData = existingFormData || new FormData();

  config.indices = !!config.indices;
  config.nullsAsUndefineds = !!config.nullsAsUndefineds;
  config.booleansAsIntegers = !!config.booleansAsIntegers;
  config.allowEmptyArrays = !!config.allowEmptyArrays;

  if (isUndefined(obj)) {
    return formData;
  }
  if (isNull(obj)) {
    if (!config.nullsAsUndefineds) {
      formData.append(keyPrefix, '');
    }
  } else if (isBoolean(obj)) {
    if (config.booleansAsIntegers) {
      formData.append(keyPrefix, obj ? '1' : '0');
    } else {
      formData.append(keyPrefix, obj);
    }
  } else if (isArray(obj)) {
    if (obj.length) {
      obj.forEach((value: any, index: any) => {
        const key = `${keyPrefix}[${config.indices ? index : ''}]`;

        objectToFormData(value, config, formData, key);
      });
    } else if (config.allowEmptyArrays) {
      formData.append(`${keyPrefix}[]`, '');
    }
  } else if (isDate(obj)) {
    formData.append(keyPrefix, obj.toISOString());
  } else if (isObject(obj) && !isFile(obj) && !isBlob(obj)) {
    Object.keys(obj).forEach(rowKey => {
      const value = obj[rowKey];
      let key = rowKey;

      if (isArray(value)) {
        while (rowKey.length > 2 && rowKey.lastIndexOf('[]') === rowKey.length - 2) {
          key = rowKey.substring(0, rowKey.length - 2);
        }
      }

      const newKey = keyPrefix ? `${keyPrefix}[${key}]` : key;

      objectToFormData(value, config, formData, newKey);
    });
  } else {
    formData.append(keyPrefix, obj);
  }

  return formData;
};

/**
 * 统一header数据结构
 */
export function unifyHeaders(headers?: HeadersInit): Record<string, string> {
  const newHeaders: Record<string, string> = {};

  if (!headers) {
    return newHeaders;
  }

  if (Array.isArray(headers)) {
    headers.forEach(value => {
      newHeaders[value[0]] = value[1];
    });
    return newHeaders;
  }

  if (headers?.forEach) {
    (headers as Headers).forEach((value, key) => {
      newHeaders[key] = value;
    });
    return newHeaders;
  }

  return headers as Record<string, string>;
}

class HttpFetcherRunOptions {
  /** 运行过程中是否禁用缓存 */
  disableCache? = true;
}

export class HttpFetcher {
  private caches: { [key: string]: Promise<any> } = {};

  /** 是否短时间临时禁用缓存（供runWithoutCache使用，>0表示禁用，这里使用计数方式来支持嵌套） */
  private disableCacheTemporarily = 0;

  /**
   * 生成缓存key
   * @param url url
   * @param body body
   * @returns
   */
  private makeCacheKey(url: string, body?: BodyInit) {
    const tokens: string[] = [url];
    if (body) {
      tokens.push(typeof body === 'string' ? body : JSON.stringify(body));
    }
    return tokens.join('|');
  }

  private async beforeFetch(
    url: string,
    rawOptions?: HttpFetcherOptions,
  ): Promise<{ url: string; options: HttpFetcherOptions }> {
    const token = localStorage.getItem(DOC_TOKEN) || sessionStorage.getItem(DOC_TOKEN);

    const options: HttpFetcherOptions = {
      cacheKey: rawOptions.enableCache ? url : null,
      cache: 'no-cache',
      ...rawOptions,
      headers: {
        'accept-encoding': 'gzip, deflate, br',
        ...rawOptions.headers,
      },
    };

    if (token) {
      Object.assign(options.headers, { Authorization: `Token ${token}` });
    }

    return { url, options };
  }

  async fetch(rawUrl: string, rawOptions: HttpFetcherOptions = {}): Promise<HttpFetcherFullInfo | any> {
    if (this.disableCacheTemporarily > 0) {
      rawOptions.enableCache = false;
    } // 临时禁用缓存
    const { url, options } = await this.beforeFetch(rawUrl, rawOptions);
    const { enableCache, cacheKey, body, returnFullInfo } = options;

    const doFetch = async () => {
      const request = new Request(url, options);
      let response;
      let result;
      try {
        response = await fetch(request);
        result = await response?.json();
      } catch (e) {}

      const fullInfo = {
        request,
        response,
        responseJson: result,
      };

      if (returnFullInfo) {
        return fullInfo;
      } // 返回完整信息

      if (!result.success) {
        console.error(`[Fetcher]"${url}"请求失败:`, result);
        throw result; // [兼容]老的逻辑，直接用结果抛出异常 TODO：规范化为正常的Error对象
      }

      return result.data; // [兼容]仅返回结果值
    };

    // 含缓存预加载的fetch
    if (enableCache) {
      const key = cacheKey || this.makeCacheKey(rawUrl, body);
      if (!this.caches[key]) {
        this.caches[key] = doFetch();
      }

      return this.caches[key];
    }

    // 正常取数
    return doFetch();
  }

  wrapFetch(rawUrl: string, rawBody?: any, rawOptions: HttpFetcherOptions = {}) {
    let options = { ...rawOptions };
    const method = options.method?.toUpperCase();

    // POST等请求强制采用JSON方式编码body
    if (['POST', 'DELETE', 'PUT'].includes(method)) {
      let headers = unifyHeaders(options.headers);
      const contentType = headers['content-type'];
      const isFormData = contentType?.toLocaleLowerCase().includes('multipart/form-data');

      if (isFormData) {
        /**
         * https://stackoverflow.com/questions/3508338/what-is-the-boundary-in-multipart-form-data
         * 移除 content-type: multipart/form-data;
         * 让浏览器自动生成 boundary, 否则需要手动生成
         */
        delete headers['content-type'];
      } else {
        headers = {
          'content-type': 'application/json',
          ...headers,
        };
      }

      options = {
        ...options,
        headers,
        body: isFormData ? objectToFormData(options.body) : JSON.stringify(rawBody),
      };
    }

    return this.fetch(rawUrl, options);
  }

  /**
   * 创建用于RTK Query的baseQuery配置
   * @example
   * ```ts
   * export const pontApis = createApi({
   *   reducerPath: 'pontApis',
   *   baseQuery: httpFetcher.createBaseQuery(),
   *   endpoints: () => ({}),
   * });
   * ```
   */
  createBaseQuery() {
    return async (
      args: string | FetchArgs,
      baseQueryApi?,
      extraOptions?: {
        getFetchExtraConfig?: () => FetchExtraConfig;
      },
    ) => {
      const options: FetchArgs & HttpFetcherOptions =
        typeof args === 'string' ? { url: args, method: 'GET' } : { ...args };
      // 明确返回全量信息
      options.returnFullInfo = true;

      const meta = (await this.wrapFetch(options.url, options.body, options)) as HttpFetcherFullInfo;
      const data = meta.responseJson;
      const response = meta.response;
      const fetchConfig: FetchExtraConfig = extraOptions?.getFetchExtraConfig() || new FetchExtraConfig();
      console.log('response', response);

      if (!response) {
        notification.error({
          message: 'Tips',
          description: 'system error',
        });
        return {
          error: {
            status: 'FETCH_ERROR',
            data: undefined,
          } as FetchBaseQueryError,
          meta,
        };
      }

      if (response?.status === 500) {
        notification.error({
          message: 'Tips',
          description: response.statusText,
        });
        return {
          error: {
            status: 'FETCH_ERROR',
            data: undefined,
          } as FetchBaseQueryError,
          meta,
        };
      }
      if (response?.status === 401) {
        message.error(data?.message?.detail || data?.message);
        localStorage.setItem(DOC_TOKEN, '');
        sessionStorage.setItem(DOC_TOKEN, '');
        localStorage.setItem(DOC_USINFO, '');
        browserHistory.replace('/login');
        return;
      }

      if (response?.status === 204) {
        return {
          data: '',
          meta,
        };
      }
      if (![0, '0'].includes(data?.statusCode) && data?.statusCode) {
        if (fetchConfig.defaultThrowError) {
          message.error(data?.message?.detail || data?.message);
        }
        return {
          error: data as FetchBaseQueryError,
          meta,
        };
      }

      return {
        data: data?.data,
        meta,
      };
    };
  }

  /**
   * 在fn执行期间，禁用缓存
   * 注：由于fn执行过程中存在异步，此期间禁用缓存，可能也会影响fn代码之外的取数调用逻辑（比如fn代码外也有异步请求a，请求a是需要缓存的，但在fn执行过程中，请求a才开始执行，此时会影响a使用缓存）
   * @param fn 执行逻辑
   */
  async runWithoutCache<T>(fn: () => Promise<T>): Promise<T> {
    this.disableCacheTemporarily++;
    const result = await fn();
    this.disableCacheTemporarily--;
    return result;
  }

  /**
   * 在fn执行期间，按可配置方式执行fn逻辑
   * @param fn 执行逻辑
   * @param options.disableCache 运行过程中是否禁用缓存
   * @returns
   */
  async run<T>(fn: () => Promise<T>, options?: HttpFetcherRunOptions): Promise<T> {
    if (options?.disableCache) {
      return this.runWithoutCache(fn);
    }
    return fn();
  }
}

/** 全局单例 */
export const httpFetcher = new HttpFetcher();

export interface HttpFetcherOptions extends RequestInit {
  /** 是否使用缓存预加载 */
  enableCache?: boolean;
  /** 使用指定key作为本次预加载的标识（留空默认使用url作为key标识） */
  cacheKey?: string;
  /** 是否在返回结果中同时加上Response/Request等对象数据 */
  returnFullInfo?: boolean;

  headers?: any;
}

export interface HttpFetcherFullInfo {
  request: Request;
  response: Response;
  responseJson: any;
}
