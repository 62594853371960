import { Button, Checkbox, Collapse, DatePicker, Form, Input, message, Modal, Result, Select, Steps, Spin } from 'antd';
import qs from 'query-string';
import _ from 'lodash';
import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';
import React, { useEffect, useRef, useState } from 'react';
import {
  ExclamationCircleFilled,
  MinusOutlined,
  CaretLeftOutlined,
  PlusOutlined,
  MailOutlined,
  LockFilled,
  CaretRightFilled,
} from '@ant-design/icons';
import './index.scss';
import { browserHistory, useSearchParams } from 'src/router';
import { DOC_TOKEN, DOC_USINFO } from 'src/utils/Appoint';
import { useUpdateEffect } from 'ahooks';
import { apiPatient } from 'src/services/doctors/mods/patient';
import { GenderList } from 'src/utils/Definition';
import usePhion from 'src/hook/usePhion';
import Four from './Four';

const Book: React.FC = () => {
  const [modal, contextHolder] = Modal.useModal();

  const [form1] = Form.useForm();

  const [form2] = Form.useForm();

  const [isMobile] = usePhion();

  const oldtoken = localStorage.getItem(DOC_TOKEN);

  const [token, setToken] = useState(oldtoken);

  /** 医院信息 */
  const [doctorsInfo, setDoctorsInfo] = useState<any>({});

  const [current, setCurrent] = useState(0);

  const [oneData, setOneData] = useState([]);
  const [threeData, setThreeData] = useState([]);
  const [threeNoneData, setThreeNoneData] = useState([]);

  const [fourDate, setFourDate] = useState(dayjs());
  const [fourDataList, setFourDataList] = useState([]);

  const [selectData, setSelectData] = useState<any>({});

  const [sixData, setSixData] = useState<any>({});

  const [isPaymentRequired, setIsPaymentRequired] = useState(false);

  const [isAddappointment, setIsAddappointment] = React.useState(false);

  const [isLogin, setIsLogin] = React.useState(!!oldtoken);

  const [logType, setLogType] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const formatName = 'YYYY-MM-DD';

  const { practice } = useSearchParams();

  const ws = useRef<WebSocket>(null);

  const clearApp = () => {
    const search = window.location.search;
    const { isClear } = qs.parse(search);
    if (isMobile || !isClear) {
      browserHistory.push('/appointment');
    } else {
      window.top?.postMessage('DoctorsHubClear', '*');
    }
  };

  const init = () => {
    if (!ws.current || ws.current?.readyState === 3) {
      const search = window.location.search;
      ws.current = new WebSocket(
        `${process.env.REACT_APP_WEBSOCKET_URL}/ws/patient/booking/practice/${practice}/${search}`,
      );
      ws.current.addEventListener('message', onMessage);
      ws.current.onclose = event => {
        message.error('WebSocket is closed now.');
        setLoading(false);
      };
      ws.current.addEventListener('error', function (event) {
        message.error('Cannot establish connection to practice, please try again later or contact practice.');
        setLoading(false);
      });
    }
  };

  const handleBack = () => {
    if (isPaymentRequired) {
      return setIsPaymentRequired(false);
    }
    if (isAddappointment) {
      return setIsAddappointment(false);
    }
    if (logType) {
      return setLogType('');
    }
    if (current !== 0) {
      setCurrent(current - 1);
    }
  };
  const handleNext = React.useCallback(() => {
    if (current < 6) {
      setCurrent(current + 1);
    }
  }, [current]);

  /** 收到消息 */
  const onMessage = event => {
    setLoading(false);
    const { type, data, ...option } = event.data ? JSON.parse(event.data) : ({} as any);
    switch (type) {
      case 'websocket_connection':
        if (option?.isSucceed) {
          setDoctorsInfo(data);
        } else {
          message.error('Cannot establish connection to practice, please try again later or contact practice.');
        }
        break;

      /** 第二步 */
      case 'appointment_types_handler':
        const therrData = _.filter(_.keys(data), item => item !== 'none').map(item => ({
          label: item,
          key: uuid(),
          children: data[item],
        }));
        const noneData = data['none'] || [];
        setThreeData(therrData || []);
        setThreeNoneData(noneData);
        break;

      /** 第三步 */
      case 'practitioner_range_appointments_handler':
        setFourDataList(data || []);
        break;
      /** 验证银行卡 */
      case 'payment_required_check_handler':
        if (data?.isPaymentRequired) {
          setIsPaymentRequired(true);
        } else {
          getMemberList();
          setCurrent(3);
        }
        break;
      /** 第四步  */
      case 'family_members_handler':
        setOneData(data || []);

        break;
      /** 第六步 */
      case 'book_appointment_handler':
        setSixData(data);
        setCurrent(5);
        break;
      default:
        break;
    }
  };

  const handleSend = data => {
    const info = JSON.stringify(data);
    if (ws.current && ws.current?.readyState === 1) {
      ws.current.send(info);
    }
  };

  const getMemberList = () => {
    if (token) {
      handleSend({
        type: 'family_members_handler',
        method: 'get',
        data: {
          token,
        },
      });
    }
  };

  useEffect(() => {
    init();
  }, []);

  useUpdateEffect(() => {
    getMemberList();
  }, [token]);

  useEffect(() => {
    const node = document.querySelector('.ant-steps-item-process');
    if (node) {
      node.scrollIntoView({ inline: 'center' });
    }
  }, [current]);

  const handleItem = (item, type, isNext = true) => {
    setSelectData({ ...selectData, [type]: item });
    isNext && handleNext();
  };

  const handleTwo = item => {
    handleSend({
      type: 'new_or_existing_patient',
      method: 'request',
      data: {
        isNew: item,
      },
    });

    handleItem(item, 'two');
    setLoading(true);
    handleSend({
      type: 'appointment_types_handler',
      method: 'get',
    });
  };
  const setFourWs = (newdate = fourDate) => {
    const appointmentDate = newdate.format(formatName);
    const appointmentEndDate = newdate.add(4, 'day').format(formatName);
    handleSend({
      type: 'practitioner_range_appointments_handler',
      method: 'get',
      data: {
        appointmentDate,
        appointmentEndDate,
      },
    });
  };

  const handleThree = item => {
    const handleOk = () => {
      handleSend({
        type: 'appointment_types_handler',
        method: 'select',
        data: {
          id: item?.id,
        },
      });
      handleItem(item, 'three');
      const newdate = dayjs();
      setFourDate(newdate);
    };

    if (item?.isEnableBookingAlert && item?.bookingAlertMsg !== null) {
      modal.confirm({
        title: 'Information',
        icon: <ExclamationCircleFilled />,
        content: (
          <div style={{ maxHeight: 300, overflow: 'auto' }}>
            <div dangerouslySetInnerHTML={{ __html: item.bookingAlertMsg }} />
          </div>
        ),
        okText: 'Agree',
        cancelText: 'Disagree',
        onOk() {
          if (item?.isEnableTermsConditions && item?.termsConditions !== null) {
            modal.confirm({
              title: 'Terms and Conditions',
              icon: <ExclamationCircleFilled />,
              content: (
                <div style={{ maxHeight: 300, overflow: 'auto' }}>
                  <div dangerouslySetInnerHTML={{ __html: item.termsConditions }} />
                </div>
              ),
              okText: 'Agree',
              cancelText: 'Disagree',
              onOk() {
                handleOk();
              },
              onCancel() {
                clearApp();
              },
            });
          } else {
            handleOk();
          }
        },
        onCancel() {
          clearApp();
        },
      });
    } else if (item?.isEnableTermsConditions && item?.termsConditions !== null) {
      modal.confirm({
        title: 'Terms and Conditions',
        icon: <ExclamationCircleFilled />,
        content: (
          <div style={{ maxHeight: 300, overflow: 'auto' }}>
            <div dangerouslySetInnerHTML={{ __html: item.termsConditions }} />
          </div>
        ),
        okText: 'OK',
        cancelText: 'Cancel',
        onOk() {
          if (item?.isEnableBookingAlert && item?.bookingAlertMsg !== null) {
            modal.confirm({
              title: 'Information',
              icon: <ExclamationCircleFilled />,
              content: (
                <div style={{ maxHeight: 300, overflow: 'auto' }}>
                  <div dangerouslySetInnerHTML={{ __html: item.bookingAlertMsg }} />
                </div>
              ),
              okText: 'OK',
              cancelText: 'Cancel',
              onOk() {
                handleOk();
              },
              onCancel() {
                clearApp();
              },
            });
          } else {
            handleOk();
          }
        },
        onCancel() {
          clearApp();
        },
      });
    } else {
      handleOk();
    }
  };

  const handleFour = (item, day, time) => {
    console.log(item, day, time);

    handleSend({
      type: 'practitioner_range_appointments_handler',
      method: 'select',
      data: {
        id: item.id,
        appointmentTime: time,
        appointmentDate: day,
      },
    });
    handleItem({ ...item, appointmentTime: time, appointmentDate: day }, 'four', false);
    handleSend({
      type: 'payment_required_check_handler',
      method: 'get',
      data: {
        token,
      },
    });
  };

  const handleOne = item => {
    if (item?.isBlocked) {
      modal.error({
        title: item?.message,
      });
    } else {
      handleSend({
        type: 'family_members_handler',
        method: 'select',
        data: {
          id: item.id,
        },
      });
      handleItem(item, 'one');
    }
  };

  useUpdateEffect(() => {
    setLoading(true);
    setFourWs(fourDate);
  }, [fourDate]);

  const handleFive = () => {
    handleSend({
      type: 'book_appointment_handler',
      method: 'request',
      data: {
        token,
        patientId: selectData['one']?.id, // 第一步选择的ID
        isNew: selectData['two'], //第二步选择的结果
        appointmentType: selectData['three']?.id, // 第三步选择的ID
        appointmentDate: selectData?.four?.appointmentDate, // 第四步选择的日期
        appointmentTime: selectData['four']?.appointmentTime,
        practitioner: selectData['four']?.id,
      },
    });
  };

  /** 登录 */
  const [postAuthLoginTrigger, { isLoading: isloginLoading }] = apiPatient.usePostAuthLoginMutation();
  const onFinishLogin = (value, cb?: (value?: any) => void) => {
    postAuthLoginTrigger({ bodyParams: value })
      .unwrap()
      .then(res => {
        localStorage.setItem(DOC_TOKEN, res.token);
        localStorage.setItem(DOC_USINFO, JSON.stringify(res.user));
        setToken(res.token);
        setIsLogin(true);
        cb(res);
      })
      .catch(res => {
        if (_.isObject(res?.message)) {
          const errors = _.keys(res?.message).map(item => ({
            name: item,
            value: form1.getFieldValue(item),
            errors: res?.message[item],
          }));
          form1.setFields(errors);
        }
      });
  };
  const runderLogin = (cb?: (value?: any) => void) => {
    return (
      <Form className="login-form" onFinish={value => onFinishLogin(value, cb)} form={form1}>
        <Form.Item name="email" rules={[{ required: true, message: 'Input E-mail' }]}>
          <Input placeholder="E-mail" prefix={<MailOutlined />} />
        </Form.Item>

        <Form.Item name="password" rules={[{ required: true, message: 'Input Password' }]}>
          <Input.Password placeholder="Password" prefix={<LockFilled />} />
        </Form.Item>

        <Form.Item>
          <Button loading={isloginLoading} type="primary" size="large" htmlType="submit" className="login-form-button">
            SIGN IN
          </Button>
        </Form.Item>
      </Form>
    );
  };

  /** 注册 */
  const [postAuthRegisterTrigger, { isLoading: isRegLoading }] = apiPatient.usePostAuthRegisterMutation();
  const [postAuthGuestTrigger, { isLoading: isGestLoading }] = apiPatient.usePostAuthGuestMutation();
  const onFinishRegister = (value, cb: (value?: any) => void, isGest) => {
    const bodyParams = { ...value, dob: dayjs(value.dob).format('DD/MM/YYYY'), practiceId: practice };
    if (isGest) {
      postAuthGuestTrigger({ bodyParams })
        .unwrap()
        .then(res => {
          console.log(res);
          const info = { ...res, fullName: `${res?.firstName} ${res?.surname}` };
          if (res.token) {
            localStorage.setItem(DOC_TOKEN, res.token);
            localStorage.setItem(DOC_USINFO, JSON.stringify(info));
            setToken(res.token);
            setIsLogin(true);
          }
          cb(info);
        })
        .catch(res => {
          if (_.isObject(res?.message)) {
            const errors = _.keys(res?.message).map(item => ({
              name: item,
              value: form2.getFieldValue(item),
              errors: res?.message[item],
            }));
            form2.setFields(errors);
          }
        });
      return;
    }

    postAuthRegisterTrigger({ bodyParams })
      .unwrap()
      .then(res => {
        localStorage.setItem(DOC_TOKEN, res.token);
        localStorage.setItem(DOC_USINFO, JSON.stringify(res));
        setToken(res.token);
        setIsLogin(true);
        cb(res);
      })
      .catch(res => {
        if (_.isObject(res?.message)) {
          const errors = _.keys(res?.message).map(item => ({
            name: item,
            value: form2.getFieldValue(item),
            errors: res?.message[item],
          }));
          form2.setFields(errors);
        }
      });
  };
  const renderRegister = (cb: (value?: any) => void, isGest = false) => {
    const getInfo = () => (
      <>
        <Form.Item
          name="email"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ]}
        >
          <Input placeholder="E-mail" prefix={<MailOutlined />} />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
          hasFeedback
        >
          <Input.Password placeholder="Password" prefix={<LockFilled />} />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm Password" prefix={<LockFilled />} />
        </Form.Item>
      </>
    );

    return (
      <Form
        className="login-form"
        layout="vertical"
        onFinish={value => onFinishRegister(value, cb, isGest)}
        form={form2}
      >
        {!isGest && getInfo()}
        <Form.Item name="firstName" rules={[{ required: true, message: 'Please input your First Name!' }]}>
          <Input placeholder="First Name" />
        </Form.Item>
        <Form.Item name="surname" rules={[{ required: true, message: 'Please input your Surname!' }]}>
          <Input placeholder="Surname" />
        </Form.Item>
        <Form.Item name="dob" rules={[{ required: true, message: 'Please select Date of birth!' }]}>
          <DatePicker
            style={{ width: '100%' }}
            placeholder="Date of birth"
            format="DD/MM/YYYY"
            inputReadOnly={isMobile}
            disabledDate={current => {
              return current && current > dayjs().endOf('day');
            }}
          />
        </Form.Item>
        <Form.Item name="gender" rules={[{ required: true, message: 'Please select gender!' }]}>
          <Select style={{ width: '100%' }} placeholder="Select Gender" options={GenderList} />
        </Form.Item>
        <Form.Item
          name="mobile"
          rules={[
            { required: true, message: 'Please input Mobile' },
            {
              pattern: /\s*04\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*/,
              message: 'Please input the correct mobile format: 04 xxxx xxxx',
            },
          ]}
        >
          <Input placeholder="Mobile" />
        </Form.Item>

        {isGest && (
          <Form.Item name="isRequiredCreate" valuePropName="checked">
            <Checkbox>Do you want to create an account with us?</Checkbox>
          </Form.Item>
        )}
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const isShow = getFieldValue('isRequiredCreate');
            if (isShow) {
              return getInfo();
            }
            return null;
          }}
        </Form.Item>
        <Form.Item>
          <Button
            loading={isRegLoading || isGestLoading}
            type="primary"
            htmlType="submit"
            size="large"
            style={{ width: '100%' }}
          >
            CONFIRM
          </Button>
        </Form.Item>
      </Form>
    );
  };

  /** 选择方式 登录 注册 */
  const renderSelectType = (text, btnText, subText = '') => {
    return (
      <div className="doc-select-type">
        <div className="book-title">{text}</div>
        {subText && <div className="book-sub-title">{subText}</div>}
        <div className="doc-type-con">
          <div className="doc-type-item">
            {/* <div className="doc-item-tetx">文案</div> */}
            <Button
              block
              size="large"
              onClick={() => {
                setLogType('login');
              }}
            >
              Sign in
            </Button>
          </div>
          <div className="doc-type-item">
            <Button
              block
              size="large"
              onClick={() => {
                setLogType('register');
              }}
            >
              {btnText}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  /** 添加银行卡 start */
  const [postPaymentTrigger, { isLoading }] = apiPatient.usePostPaymentMutation();
  const onFinishPayment = value => {
    const bodyParams = { ...value, cardExpiry: value.cardExpiry.format('MM/YYYY') };
    postPaymentTrigger({ bodyParams, extraConfig: { defaultThrowError: false } })
      .unwrap()
      .then(() => {
        getMemberList();
        handleNext();
        setIsPaymentRequired(false);
      })
      .catch(e => {
        message.error(e.message).then(() => clearApp());
      });
  };
  const onParmentOk = value => {
    setLogType('');
    handleSend({
      type: 'payment_required_check_handler',
      method: 'get',
      data: {
        token: value.token,
      },
    });
  };
  const renderPayment = () => {
    if (isLogin) {
      return (
        <div className="book-payment-edit">
          <Form className="form" onFinish={onFinishPayment}>
            <Form.Item name="cardNumber" rules={[{ required: true, message: 'Input Card Number' }]}>
              <Input allowClear placeholder="Card Number" />
            </Form.Item>
            <div className="item">
              <Form.Item
                style={{ flex: 0.8 }}
                name="cardExpiry"
                rules={[{ required: true, message: 'Select Expired Date' }]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  picker="month"
                  placeholder="Expiry"
                  format={'MM/YYYY'}
                  inputReadOnly={isMobile}
                />
              </Form.Item>

              <Form.Item
                name="cvv"
                rules={[
                  { required: true, message: 'Input CVV' },
                  { pattern: /^\d{3}$/, message: 'Please enter the correct CVV' },
                ]}
              >
                <Input allowClear type="password" placeholder="CVV" />
              </Form.Item>
            </div>
            <Form.Item name="cardHolder" rules={[{ required: true, message: 'Input Card Holder' }]}>
              <Input allowClear placeholder="Card Holder" />
            </Form.Item>
            <div className="save">
              <Button loading={isLoading} type="primary" htmlType="submit" size="large" style={{ margin: '0 auto' }}>
                Validate
              </Button>
            </div>
          </Form>
        </div>
      );
    }
    if (logType === 'login') {
      return runderLogin(onParmentOk);
    }
    if (logType === 'register') {
      return renderRegister(onParmentOk);
    }

    return renderSelectType(
      'How do you want to book with us?',
      'Sign up',
      'To book this appointment, Epping Doctors requires a payment method. Please sign in or sign up to enter your card details.',
    );
  };
  /** 添加银行卡 end */

  /** 添加人 start */
  const onMemberLogOk = () => {
    setLogType('');
    getMemberList();
  };
  const onMemberRegOk = value => {
    handleOne(value);
    setLogType('');
  };

  const renderMember = () => {
    if (logType === 'login') {
      return runderLogin(onMemberLogOk);
    }
    if (logType === 'register') {
      return renderRegister(onMemberRegOk, true);
    }

    return renderSelectType('How do you want to book with us?', 'Booking as a guest');
  };

  const [postFamilyMemberTrigger, { isLoading: btnLoading }] = apiPatient.usePostFamilyMemberMutation();

  const onFinishOne = value => {
    const bodyParams = { ...value, dob: value.dob?.format('DD/MM/YYYY') };
    postFamilyMemberTrigger({ bodyParams })
      .unwrap()
      .then(res => {
        handleOne(res);
        setIsAddappointment(false);
        setOneData([...oneData, res]);
      });
  };

  const renderOne = () => {
    if (isAddappointment) {
      return (
        <div className="book-one">
          <div className="book-title">Who is this appointment for?</div>
          <Form layout="vertical" onFinish={onFinishOne} style={{ maxWidth: 600, margin: '0 auto' }}>
            <Form.Item name="firstName" rules={[{ required: true, message: 'Please input your First Name!' }]}>
              <Input allowClear placeholder="First Name" />
            </Form.Item>
            <Form.Item name="surname" rules={[{ required: true, message: 'Please input your Surname!' }]}>
              <Input allowClear placeholder="Surname" />
            </Form.Item>
            <Form.Item name="dob" rules={[{ required: true, message: 'Please select Date of birth!' }]}>
              <DatePicker
                style={{ width: '100%' }}
                placeholder="Date of birth"
                format="DD/MM/YYYY"
                inputReadOnly={isMobile}
                disabledDate={current => {
                  return current && current > dayjs().endOf('day');
                }}
              />
            </Form.Item>
            <Form.Item name="gender" rules={[{ required: true, message: 'Please select gender!' }]}>
              <Select style={{ width: '100%' }} placeholder="Select Gender" options={GenderList} />
            </Form.Item>

            <Form.Item>
              <Button loading={btnLoading} type="primary" htmlType="submit" size="large" style={{ width: '100%' }}>
                SAVE
              </Button>
            </Form.Item>
          </Form>
        </div>
      );
    }
    return (
      <div className="book-one">
        <div className="book-title">Who is this appointment for?</div>
        <div className="book-list">
          {oneData.length > 0 &&
            _.map(oneData, item => (
              <div className="book-item" key={item.id} onClick={() => handleOne(item)}>
                {item.fullName}
              </div>
            ))}
          {oneData.length > 0 && <div style={{ textAlign: 'center', marginBottom: 10 }}>or</div>}
          <Button
            size="large"
            block
            icon={<PlusOutlined />}
            onClick={() => {
              setIsAddappointment(true);
            }}
            style={{ color: '#4096ff', borderColor: '#4096ff' }}
          >
            Add Family Menber
          </Button>
        </div>
      </div>
    );
  };

  /** 添加人 end */

  const renderTwo = () => (
    <div className="book-two">
      <div className="book-title">Have you been to this practice before?</div>
      <div className="book-list">
        <div className="book-item" onClick={() => handleTwo(true)}>
          Yes
        </div>
        <div className="book-item" onClick={() => handleTwo(false)}>
          No
        </div>
      </div>
    </div>
  );

  const [threeActiveKey, setThreeActiveKey] = React.useState(_.map(threeData, 'key'));

  useUpdateEffect(() => {
    setThreeActiveKey(_.map(threeData, 'key'));
  }, [threeData]);

  const renderThree = () => (
    <div className="book-three">
      <div className="book-title">What is the reason for the appointment?</div>
      <Collapse
        expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
        activeKey={threeActiveKey}
        onChange={value => setThreeActiveKey(value as any)}
      >
        {_.map(threeData, ({ label, key, children }) => {
          return (
            <Collapse.Panel header={label} key={key}>
              <div className="book-list">
                {_.map(children, item => (
                  <div className="book-item item-three" key={item.id} onClick={() => handleThree(item)}>
                    <span> {item.displayName}</span>
                    <CaretRightFilled />
                  </div>
                ))}
              </div>
            </Collapse.Panel>
          );
        })}
      </Collapse>
      {threeNoneData.length > 0 && (
        <div className="book-list" style={{ margin: '20px 16px 0' }}>
          {_.map(threeNoneData, item => (
            <div className="book-item item-three" key={item.id} onClick={() => handleThree(item)}>
              <span> {item.displayName}</span>
              <CaretRightFilled />
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const renderFour = () => {
    return (
      <Four
        fourDate={fourDate}
        doctorsInfo={doctorsInfo}
        fourDataList={fourDataList}
        handleFour={handleFour}
        setFourDate={setFourDate}
        setFourDataList={setFourDataList}
      />
    );
  };

  const renderFive = () => {
    return (
      <div className="book-five">
        <div className="book-title">Please confirm your booking details</div>
        <div className="five-list">
          <div className="five-item">
            <div className="icon">
              <span className="iconfont doc-yiyuan"></span>
            </div>
            <div className="value">{doctorsInfo?.name}</div>
          </div>
          <div className="five-item">
            <div className="icon">
              <span className="iconfont doc-mianxingyishengtubiao3"></span>
            </div>
            <div className="value">{selectData?.four?.fullName}</div>
          </div>
          <div className="five-item">
            <div className="icon">
              <span className="iconfont doc-tianjiayonghu"></span>
            </div>
            <div className="value">{selectData?.one?.fullName}</div>
          </div>
          <div className="five-item">
            <div className="icon">
              <span className="iconfont doc-jiaofuriqi"></span>
            </div>
            <div className="value">
              {selectData?.four?.appointmentTime}, {dayjs(selectData?.four?.appointmentDate).format('DD/MM/YYYY')}
            </div>
          </div>
          <div className="five-item">
            <div className="icon">
              <span className="iconfont doc-liebiao"></span>
            </div>
            <div className="value">{selectData?.three?.displayName}</div>
          </div>
        </div>
        <Button
          type="primary"
          size="large"
          style={{ width: '100%', marginTop: 20, boxShadow: ' 0px 2px 4px 1px rgb(0 0 0 / 20%)' }}
          onClick={handleFive}
        >
          BOOK
        </Button>
      </div>
    );
  };
  const renderSix = () => {
    return (
      <div className="book-six">
        <div className="book-title">Your appointment has been confirmed!</div>
        <Result
          status={sixData?.isSuccessful ? 'success' : 'error'}
          subTitle={sixData?.message}
          extra={
            <Button size="large" style={{ width: '100%' }} type="primary" key="console" onClick={() => clearApp()}>
              FINISH
            </Button>
          }
        />
      </div>
    );
  };

  const renderContent = () => {
    switch (current) {
      case 0:
        return renderTwo();
      case 1:
        return renderThree();
      case 2:
        return isPaymentRequired ? renderPayment() : renderFour();
      case 3:
        return isLogin ? renderOne() : renderMember();
      case 4:
        return renderFive();
      case 5:
        return renderSix();

      default:
        return null;
    }
  };

  return (
    <div className="page-book">
      <div className="book-header">
        {current !== 0 && (
          <Button type="text" size="large" className="back" icon={<CaretLeftOutlined />} onClick={handleBack}></Button>
        )}
        <div className="step">
          <Steps
            responsive={false}
            current={current}
            items={[
              { icon: <span className="iconfont doc-kongxinwenhao"></span> },
              { icon: <span className="iconfont doc-liebiao"></span> },
              { icon: <span className="iconfont doc-mianxingyishengtubiao3"></span> },
              { icon: <span className="iconfont doc-jurassic_users"></span> },
              { icon: <span className="iconfont doc-riqi"></span> },
              { icon: <span className="iconfont doc-duigou-cu"></span> },
            ]}
          />
        </div>
      </div>
      <Spin spinning={loading}> {renderContent()}</Spin>

      {contextHolder}
    </div>
  );
};

export default Book;
