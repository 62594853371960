const usePhion = ():[boolean,number] => {
  let isMobile = false;
  if (
    typeof window.orientation !== 'undefined' ||
    /Android|iPhone|iPad|iPod/i.test(navigator.platform) ||
    /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent)
  ) {
    isMobile = true;
  }

  return [isMobile, document.body.clientWidth];
};

export default usePhion;
