/**
 * @file 公共的 store 初始化逻辑，包括 redux 中间件、devtool 初始化等
 */

import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { pontApis } from 'src/services/pontApis';

const reducer = combineReducers({
  [pontApis.reducerPath]: pontApis.reducer,
});

export const store = configureStore({
  reducer,
  preloadedState: {},
  middleware: getDefaultMiddleware => {
    return [...getDefaultMiddleware({ serializableCheck: true }), pontApis.middleware];
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof reducer>;
export type GetRootState = () => RootState;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
