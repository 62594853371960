import { Breadcrumb, Button, Divider, Empty, List, message, Popconfirm, Skeleton, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { browserHistory } from 'src/router';
import { apiPatient } from 'src/services/doctors/mods/patient';
import './Payment.scss';

const Payment: React.FC = () => {
  const [getHistoricalAppointmentTrigger, { data, isFetching: loading }] = apiPatient.useLazyGetPaymentQuery();
  const [deletePaymentByIdTrigger] = apiPatient.useDeletePaymentByIdMutation();

  const delPayment = (item: defs.doctors.PatientPaymentCardModel) => {
    deletePaymentByIdTrigger({ id: item.id })
      .unwrap()
      .then(() => {
        message.success('Successfully deleted');
        getHistoricalAppointmentTrigger({});
      });
  };

  useEffect(() => {
    getHistoricalAppointmentTrigger({});
  }, []);
  return (
    <div className="page-payment">
      <div className="payment-header">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/account">Account</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Payments</Breadcrumb.Item>
        </Breadcrumb>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => browserHistory.push('/account/payment/edit')}
        ></Button>
      </div>

      <div className="payment-content">
        <Spin spinning={loading}>
          {data?.length > 0 ? (
            _.map(data, (item: defs.doctors.PatientPaymentCardModel) => (
              <div className="payment-item">
                <div className="icon">
                  <span className="iconfont doc-xinyongqia"></span>
                </div>
                <div className="content">
                  <div>{item.cardNumber}</div>
                  <div>{item.cardHolder}</div>
                </div>
                <div className="action">
                  <Popconfirm
                    title="Are you sure to delete?"
                    onConfirm={() => delPayment(item)}
                    okText="Yes"
                    cancelText="No"
                    placement="left"
                  >
                    <span className="iconfont doc-shanchu" style={{ color: 'red' }}></span>
                  </Popconfirm>
                </div>
              </div>
            ))
          ) : (
            <Empty />
          )}
        </Spin>
      </div>
    </div>
  );
};

export default Payment;
