import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from './router';
import { getRotes } from './pages';
import { Provider } from 'react-redux';
import 'antd/dist/reset.css';
import './style';
import { store } from './redux';
import { message, notification } from 'antd';

message.config({
  top: 100,
  maxCount: 2,
});

notification.config({
  placement: 'top',
  top: 100,
});
const node = document.querySelector('.spinner');
if (node) {
  document.body.removeChild(node);
}
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>{getRotes()}</BrowserRouter>
    </Provider>
  </React.StrictMode>,
);
