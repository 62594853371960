import React from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Breadcrumb, Button, DatePicker, Form, Input, message, Select } from 'antd';
import { Link } from 'react-router-dom';
import { DOC_USINFO } from 'src/utils/Appoint';
import { apiPatient } from 'src/services/doctors/mods/patient';
import { GenderList } from 'src/utils/Definition';
import './Profile.scss';
import usePhion from 'src/hook/usePhion';

const Profile: React.FC = () => {
  const [form] = Form.useForm();
  const [isMobile] = usePhion();
  const userInfoJson = localStorage.getItem(DOC_USINFO);
  const userInfo = userInfoJson ? JSON.parse(userInfoJson) : {};
  const [putAuthUserProfileTrigger, { isLoading }] = apiPatient.usePutAuthUserProfileMutation();
  const onFinish = value => {
    const bodyParams = { ...value, dob: value.dob?.format('DD/MM/YYYY') };
    putAuthUserProfileTrigger({ bodyParams })
      .unwrap()
      .then(res => {
        localStorage.setItem(DOC_USINFO, JSON.stringify({ ...userInfo, ...res }));
        message.success('Operation successful');
      });
  };

  const initialValues = {
    ...userInfo,
    gender: _.toNumber(userInfo?.gender),
    dob: userInfo?.dob ? dayjs(userInfo.dob, 'DD/MM/YYYY') : undefined,
  };

  return (
    <div className="page-profile">
      <Breadcrumb style={{ marginBottom: 20 }}>
        <Breadcrumb.Item>
          <Link to="/account">Account</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Profile</Breadcrumb.Item>
      </Breadcrumb>

      <Form
        layout="vertical"
        form={form}
        initialValues={initialValues}
        onFinish={onFinish}
        style={{ maxWidth: 600, margin: '0 auto' }}
      >
        <Form.Item name="firstName" rules={[{ required: true, message: 'Please input your First Name!' }]}>
          <Input placeholder="First Name" />
        </Form.Item>
        <Form.Item name="surname" rules={[{ required: true, message: 'Please input your Surname!' }]}>
          <Input placeholder="Surname" />
        </Form.Item>
        <Form.Item name="dob" rules={[{ required: true, message: 'Please select Date of birth!' }]}>
          <DatePicker
            style={{ width: '100%' }}
            placeholder="Date of birth"
            format="DD/MM/YYYY"
            inputReadOnly={isMobile}
            disabledDate={current => {
              return current && current > dayjs().endOf('day');
            }}
          />
        </Form.Item>
        <Form.Item name="gender" rules={[{ required: true, message: 'Please select gender!' }]}>
          <Select style={{ width: '100%' }} placeholder="Select Gender" options={GenderList} />
        </Form.Item>
        <Form.Item
          name="mobile"
          rules={[
            { required: true, message: 'Please input Mobile' },
            {
              validator: (rule, value) => {
                if (value.length > 16) {
                  return Promise.reject(new Error('Please enter the correct phone number'));
                }
                return Promise.resolve();
              },
            },
            {
              pattern: /^\s*04\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*$/,
              message: 'Please enter the correct phone number',
            },
          ]}
        >
          <Input placeholder="Mobile" />
        </Form.Item>
        <Form.Item>
          <Button loading={isLoading} type="primary" htmlType="submit" size="large" style={{ width: '100%' }}>
            SAVE
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Profile;
