/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-inferrable-types */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/**
 * @description patient user login api
 */

import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/react';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { makeSwaggerUrl } from 'src/utils/Url';
import { FetchExtraConfig } from 'src/utils/HttpFetcher';
import { doctors as defs } from '../../baseClass';

const urlPath = '/api/patient/auth/register/';

/**
 * path内参数：用于替换"/dashboard/{id}"形式的路径
 */
export class PathParams {}

export class QueryParams {}

export class FormDataParams {}

export type BodyParams = defs.doctors.PatientUserRegisterModelRequest & FormDataParams;

/**
 * 完整请求参数：包含 path参数（已打平）、query参数（QueryParams） 和 body请求数据（BodyParams，用于POST等请求）
 */
export class Params extends PathParams {
  /** url的query参数 */
  queryParams?: QueryParams;

  /** body参数：用于POST等请求 */
  bodyParams?: BodyParams;

  /** 额外的配置参数，用于处理接口，不作为实际请求参数 */
  extraConfig?: FetchExtraConfig;
}

/**
 * 请求返回数据
 */
export type Response = any;

/** 请求返回的默认数据 */
export const responseInitialValue: any = undefined;

/**
 * RTK版请求服务
 */
export function endpoint<BaseQuery extends BaseQueryFn, TagTypes extends string, ReducerPath extends string>(
  builder: EndpointBuilder<BaseQuery, TagTypes, ReducerPath>
) {
  let fetchExtraConfig: FetchExtraConfig;
  return builder.mutation<Response, Params>({
    query: ({ queryParams, bodyParams, extraConfig, ...pathParams }: Params = new Params()) => {
      fetchExtraConfig = extraConfig && { ...new FetchExtraConfig(), ...extraConfig };
      return {
        url: makeSwaggerUrl(urlPath, pathParams, queryParams),
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: bodyParams
      };
    },
    extraOptions: {
      getFetchExtraConfig: () => fetchExtraConfig
    }
  });
}
