import React from 'react';
import qs from 'query-string';
import { useNavigate, useLocation, NavigateProps, BrowserRouterProps, Router } from 'react-router-dom';
import { createBrowserHistory, BrowserHistory, To } from 'history';
import _ from 'lodash';
import { DOC_PRACTICE, DOC_TOKEN } from 'src/utils/Appoint';

const browserHistory = createBrowserHistory({ window });

const { useEffect, useLayoutEffect, useRef, useState } = React;

/**
 * @description 由于V6的BrowserRouter中的browserHistory是单独创建，导致了项目中的browserHistory失效
 * @ignore 外部人员可以忽略此处更改
 */
export function BrowserRouter({ basename, children }: BrowserRouterProps) {
  const historyRef = useRef<BrowserHistory>(browserHistory);

  const history = historyRef.current;
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => {
    history.listen(setState);
  }, [history]);

  return (
    <Router basename={basename} location={state.location} navigationType={state.action} navigator={history}>
      {children}
    </Router>
  );
}

/**
 * 重写 V6的 Navigate组件，由于原生组件replace时，没有加search
 * @param props
 * @returns
 */
export const Navigate: React.FC<NavigateProps> = (props: NavigateProps) => {
  const { to, replace, state } = props;
  const navigate = useNavigate();
  const { search } = useLocation();
  useEffect(() => {
    navigate(to + search, {
      replace,
      state,
    });
  });
  return null;
};

function isLogin(to: To) {
  const tooken = localStorage.getItem(DOC_TOKEN);
  const pathname = _.isString(to) ? to : to.pathname;

  if (tooken || _.includes(pathname, '/book')) {
    return to;
  }
  if (typeof to === 'string') {
    const oldSearch = to.split('?')[1];
    return `/login?${oldSearch}`;
  }

  return { ...to, pathname: '/login' };
}

function addSlugToUrl(to: To) {
  let newToUrl = to;
  // eslint-disable-next-line no-restricted-globals
  const query = qs.parse(location.search);
  const querySlug = query?.practice;
  const queryParameter = query?.parameter;
  const practice = localStorage.getItem(DOC_PRACTICE);

  /** 前端缓存的租户slug */
  const targetSlug = querySlug || practice;

  /** to有两种类型
   * 分别对string类型和Path类型进行处理
   */
  if (typeof to === 'string') {
    const oldSearch = to.split('?')[1];
    if (oldSearch) {
      const oldSlug = qs.parse(oldSearch)?.practice;
      if (oldSlug) {
        newToUrl = to;
      } else {
        newToUrl = `${to}&practice=${targetSlug}`;
      }
    } else {
      newToUrl = `${to}?practice=${targetSlug}`;
    }
  } else {
    /** 兼容不规范写法(在pathname中写search) */
    const realPathname = to.pathname.split('?')[0];
    const oldPathSearch = to.pathname.split('?')[1];
    if (oldPathSearch) {
      const oldSearch = qs.parse(oldPathSearch);
      if (oldSearch?.practice) {
        newToUrl = {
          ...to,
        };
      } else {
        const temp = qs.stringify(oldSearch);
        newToUrl = {
          ...to,
          pathname: realPathname,
          search: `${temp}&practice=${targetSlug}`,
        };
      }
    } else {
      const search = qs.parse(to.search);
      let newSearch = '';
      if (search && !_.isEmpty(search)) {
        if (search.practice) {
          newSearch = to.search;
        } else {
          newSearch = `${to.search}&practice=${targetSlug}`;
        }
      } else {
        newSearch = `?practice=${targetSlug}`;
      }

      newToUrl = {
        ...to,
        search: newSearch,
      };
    }
  }
  return newToUrl;
}

const oldHistoryPush = browserHistory.push;
const oldHistoryReplace = browserHistory.replace;
browserHistory.push = (to: To, state?: any) => {
  const url = addSlugToUrl(to);
  oldHistoryPush(isLogin(url), state);
};
browserHistory.replace = (to: To, state?: any) => {
  const url = addSlugToUrl(to);
  oldHistoryReplace(isLogin(url), state);
};

export { browserHistory };
