export const GenderList = [
  {
    label: 'Prefer to not say',
    value: 0,
  },
  {
    label: 'Female',
    value: 1,
  },
  {
    label: 'Male',
    value: 2,
  },
  {
    label: 'None-binary',
    value: 3,
  },
  {
    label: 'Gender diverse',
    value: 4,
  },
  {
    label: 'Transgender',
    value: 5,
  },
  {
    label: 'Different identity',
    value: 6,
  },
];
